import { useErrorNotification } from '@/components/hooks/toast/use-error-notification'
import { cn } from '@/lib/utils'
import { SmallCompany } from '@/schemas/entities/small-company.schema'
import { autoCompleteByName, getCompaniesByName } from '@/services/company.service'
import { Company } from '@/shared/schemas/entity/company.schema'
import { Button } from '@/shared/ui/base/button'
import { CommandDialog, CommandInput, CommandItem, CommandList } from '@/shared/ui/base/command'
import { formatNumberWithComma } from '@/shared/utils/number-utils'
import { isEmpty } from '@/shared/utils/string-utils'
import { useQuery } from '@tanstack/react-query'
import debouce from 'lodash.debounce'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Spinner } from '../../../../shared/ui/base/spinner'
import CompanyLogo from '../../../../shared/ui/company/company-logo'
import CreditsBadge from '../../../../shared/ui/credits/credits-badge'
import CompanyModal from '../../company/company-modal/company-modal'
import { OpenDialogHandle } from '../../dialog/dialog-handle-interface'
import OutOfCreditsDialog from '../../dialog/out-of-credits-dialog'

const default_companies: SmallCompany[] = [
    {
        name: 'Apple',
        logo: 'https://logo.clearbit.com/apple.com',
        domain: 'apple.com',
        industry: 'Consumer Electronics',
        country_code: 'US',
        employee_count: 147000,
        num_jobs: 13638,
        num_technologies: 731,
    },
    {
        name: 'Google',
        logo: 'https://logo.clearbit.com/google.com',
        domain: 'google.com',
        industry: 'Internet',
        country_code: 'US',
        employee_count: 147000,
        num_jobs: 7851,
        num_technologies: 437,
    },
    {
        name: 'Microsoft',
        logo: 'https://logo.clearbit.com/microsoft.com',
        domain: 'microsoft.com',
        industry: 'Software',
        country_code: 'US',
        employee_count: 147000,
        num_jobs: 15049,
        num_technologies: 857,
    },
    {
        name: 'JPMorgan Chase Bank, N.A.',
        logo: 'https://logo.clearbit.com/jpmorgan.com',
        domain: 'jpmorgan.com',
        industry: 'Internet',
        country_code: 'US',
        employee_count: 76000,
        num_jobs: 30351,
        num_technologies: 1161,
    },
]

const MIN_SEARCH_QUERY_LENGTH = 1

export function CompanySearchBar() {
    const [searchParams] = useSearchParams()
    const [searchQuery, setSearchQuery] = useState('')
    const [selectedCompany, setSelectedCompany] = useState<Company>()
    const [isCommandDialogOpen, setIsCommandDialogOpen] = useState(false)
    const companyModalRef = useRef<OpenDialogHandle>(null)
    const outOfCreditsDialogRef = useRef<OpenDialogHandle>(null)
    const { showErrorNotification } = useErrorNotification({ isError: false })
    const {
        data: companies = [],
        isFetching,
        refetch: refetch,
    } = useQuery<SmallCompany[]>({
        queryKey: ['companies', 'name', '0', searchQuery],
        queryFn: () => {
            if (searchQuery.length > MIN_SEARCH_QUERY_LENGTH) {
                return autoCompleteByName(searchQuery)
            }
            return []
        },
        staleTime: Infinity,
        enabled: searchQuery !== '',
    })

    const handleChange = (query: string) => {
        setSearchQuery(query)
    }

    const debouncedResults = useMemo(() => {
        return debouce(handleChange, 300)
    }, [])

    useEffect(() => {
        return () => {
            debouncedResults.cancel()
        }
    })

    useEffect(() => {
        if (searchQuery !== '') {
            refetch()
        }
    }, [searchQuery])

    useEffect(() => {
        const down = (e: KeyboardEvent) => {
            if ((e.key === 'k' && (e.metaKey || e.ctrlKey)) || e.key === '/') {
                if (
                    (e.target instanceof HTMLElement && e.target.isContentEditable) ||
                    e.target instanceof HTMLInputElement ||
                    e.target instanceof HTMLTextAreaElement ||
                    e.target instanceof HTMLSelectElement
                ) {
                    return
                }

                e.preventDefault()
                setIsCommandDialogOpen((open) => !open)
            }
        }

        document.addEventListener('keydown', down)
        return () => document.removeEventListener('keydown', down)
    }, [])

    useEffect(() => {
        if (searchParams.get('search-company')) {
            runCommand(searchParams.get('search-company') || '')
        }
    }, [searchParams])

    const runCommand = useCallback((companyName: string) => {
        setIsCommandDialogOpen(false)
        setSearchQuery('')
        getCompaniesByName([companyName])
            .then((res) => {
                if (res.metadata.truncated_companies > 0) {
                    outOfCreditsDialogRef.current?.open()
                    return
                }
                const companies = res.data.filter((c) => c.name === companyName)
                if (companies.length === 0) {
                    showErrorNotification()
                    return
                }
                const company = companies[0]
                setSelectedCompany(company)
                companyModalRef.current?.open()
            })
            .catch(() => {
                showErrorNotification()
            })
    }, [])

    return (
        <>
            <Button
                variant="outline"
                className={cn(
                    'relative h-10 w-full justify-start rounded-[0.5rem] bg-background text-sm font-normal text-muted-foreground shadow-none sm:pr-12 md:w-40 lg:w-80'
                )}
                onClick={() => setIsCommandDialogOpen(true)}
            >
                <span className="hidden lg:inline-flex">Search companies or websites...</span>
                <span className="inline-flex lg:hidden">Search...</span>
                <kbd className="pointer-events-none absolute right-[0.3rem] top-[0.5rem] hidden h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium opacity-100 sm:flex">
                    <span className="text-xs">⌘</span>K
                </kbd>
            </Button>
            <CompanyModal ref={companyModalRef} company={selectedCompany} />
            <OutOfCreditsDialog ref={outOfCreditsDialogRef} />

            <CommandDialog
                open={isCommandDialogOpen}
                onOpenChange={() => {
                    setIsCommandDialogOpen(!isCommandDialogOpen)
                    setSearchQuery('')
                }}
                shouldFilter={false}
            >
                <CommandInput placeholder="Search companies or websites" onValueChange={debouncedResults} />
                <CommandList>
                    {isEmpty(searchQuery) &&
                        default_companies.map((item) => (
                            <CompanyRow key={item.name} company={item} openCompanyModal={runCommand} />
                        ))}
                    {!isEmpty(searchQuery) && (
                        <>
                            {isFetching && (
                                <div className="flex justify-center py-6">
                                    <Spinner size="default" />
                                </div>
                            )}
                            {!isFetching && (
                                <>
                                    {companies.length > 0 &&
                                        companies.map((item) => (
                                            <CompanyRow key={item.name} company={item} openCompanyModal={runCommand} />
                                        ))}
                                    {companies.length === 0 && searchQuery.length > MIN_SEARCH_QUERY_LENGTH && (
                                        <div className="flex items-center p-4">
                                            <p className="text-sm text-center">No results found for "{searchQuery}"</p>
                                        </div>
                                    )}
                                    {companies.length === 0 && searchQuery.length <= MIN_SEARCH_QUERY_LENGTH && (
                                        <div className="flex items-center p-4">
                                            <p className="text-sm text-center text-red-600">
                                                Please enter at least {MIN_SEARCH_QUERY_LENGTH + 1} characters to search
                                                for companies or websites.
                                            </p>
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </CommandList>
                <div className="flex py-2 justify-end px-2 bg-gray-100 border-t">
                    <p className="text-xs text-end">
                        Reveal company data in return for <CreditsBadge credits={1} type="company" size="sm" />
                    </p>
                </div>
            </CommandDialog>
        </>
    )
}

function CompanyRow({
    company,
    openCompanyModal: runCommand,
}: {
    company: SmallCompany
    openCompanyModal: (companyName: string) => void
}) {
    const subtitle = `${company.domain ? company.domain : ''}${company.industry ? ` · ${company.industry}` : ''}${company.employee_count ? ` ·  ${formatNumberWithComma(company.employee_count)} employees` : ''}`
    return (
        <CommandItem
            value={company.name + company.domain}
            className="flex items-center space-x-4"
            onSelect={() => {
                runCommand(company.name)
            }}
        >
            <CompanyLogo domain={company.domain} logo={company.logo} width={10} />
            <div className="flex flex-col space-y-1 items-start">
                <p className="text-sm" translate="no">
                    {company.name}
                </p>
                <p className="text-xs">{subtitle}</p>
                <p className="text-xs text-muted-foreground">
                    {formatNumberWithComma(company.num_jobs || 0)} jobs ·{' '}
                    {formatNumberWithComma(company.num_technologies || 0)} technologies
                </p>
            </div>
        </CommandItem>
    )
}
