const REGEX_PATTERN_NUMBER_WITH_COMMA = /\B(?=(\d{3})+(?!\d))/g

export function formatNumberWithComma(num: number | null | undefined): string {
    if (num === null || num === undefined) return ''
    if (num === 0) return '0'
    return num.toString().replace(REGEX_PATTERN_NUMBER_WITH_COMMA, ',')
}

export function formatCompactNumber(
    number: number,
    prefix: string = '',
    compactDisplay: 'short' | 'long' = 'short'
): string {
    const formatter = Intl.NumberFormat('en', { notation: 'compact', compactDisplay: compactDisplay })
    const formattedNumber = formatter.format(number)
    return prefix + formattedNumber
}

export default function formatNumberToNDigits(num: number | null | undefined, digits: number): string {
    if (num === null || num === undefined) return ''
    const lookup = [
        { value: 1, symbol: '' },
        { value: 1e3, symbol: 'k' },
        { value: 1e6, symbol: 'M' },
        { value: 1e9, symbol: 'B' },
    ]
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
    const item = lookup
        .slice()
        .reverse()
        .find(function (item) {
            return num >= item.value
        })
    return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0'
}

export function formatMoney(num: number, currency: string, digits?: number): string {
    const number = digits ? num.toFixed(digits) : num.toString()
    const [integerPart, decimalPart] = number.split('.')
    const formattedIntegerPart = integerPart.replace(REGEX_PATTERN_NUMBER_WITH_COMMA, ',')
    const formattedNumber = decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart
    return currency === 'eur' ? `${formattedNumber}€` : `$${formattedNumber}`
}

export function formatShortMoney(num: number, currency: string): string {
    const formattedNumber = formatNumberToNDigits(num, 1)
    return currency === 'eur' ? `${formattedNumber}€` : `$${formattedNumber}`
}

export function formatStripeNumber(num: number): number {
    return num / 100
}
