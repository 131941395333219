import React, { useEffect, useMemo, useState } from 'react'
import { ControllerRenderProps, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import debouce from 'lodash.debounce'
import * as z from 'zod'
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/ui/base/form'
import { Button } from '@/shared/ui/base/button'
import { Checkbox } from '@/shared/ui/base/checkbox'
import { FormLabel } from '@/components/ui/base/form'
import { Laptop } from 'lucide-react'
import { Technology } from '@/schemas/entities/technology.schema'
import { getTechnologies, getTechnologiesByName, getTechnologiesBySlugs } from '@/services/technology.service'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { Spinner } from '../../../shared/ui/base/spinner'
import { useIntersectionObserver } from 'usehooks-ts'
import { formatNumberWithComma } from '@/shared/utils/number-utils'
import { areObjectsEqual } from '@/lib/search-params-utils'
import TechnologyLogo from '@/shared/ui/technology/technology-logo'
import { Tag } from './components/tag'
import { Cross2Icon } from '@radix-ui/react-icons'

const FormSchema = z.object({
    items: z.array(z.string()),
})

export function SearchFilterValuePreviewTechnologies({ value }: { value: string[] }) {
    const { data = [], isLoading } = useQuery<Technology[]>({
        queryKey: ['technologies', 'slug', '0', value],
        queryFn: () => {
            return getTechnologiesBySlugs(value)
        },
        staleTime: Infinity,
    })
    const length = value ? value.length : 0
    return (
        <div className="flex items-center space-x-1">
            {!isLoading && (
                <>
                    {data &&
                        data.length > 0 &&
                        data.slice(0, 2).map((techology: Technology, index: number) => (
                            <div key={techology.slug} className="flex items-center space-x-1">
                                <TechnologyLogo href={techology.logo} width={4} />
                                <p className="text-sm">
                                    {techology.name}
                                    {index < 1 && length > 1 && ', '}
                                </p>
                            </div>
                        ))}
                    {length > 2 && <p className="text-sm">{`+ ${length - 2}`}</p>}
                </>
            )}
            {isLoading && <Spinner size="sm" />}
        </div>
    )
}

export function SearchFilterFormTechnologies({
    value,
    onChangeValue,
    onInteractOutside,
    closePopover,
}: {
    id: string
    value: string[]
    onChangeValue: (_value: string[] | undefined) => void
    onInteractOutside: boolean
    closePopover: () => void
}) {
    const [searchQuery, setSearchQuery] = useState('')
    const { isIntersecting: isVisible, ref: loadingButtonRef } = useIntersectionObserver({ threshold: 0.5 })
    const form = useForm<z.infer<typeof FormSchema>>({
        resolver: zodResolver(FormSchema),
        defaultValues: { items: value || [] },
    })
    const { data: originalSelectedTechnologies = [] } = useQuery<Technology[]>({
        queryKey: ['technologies', 'slug', '0', value],
        queryFn: () => {
            return getTechnologiesBySlugs(value)
        },
        staleTime: Infinity,
    })
    const [selectedTechnologies, setSelectedTechnologies] = useState<Technology[]>(originalSelectedTechnologies)
    const {
        data: filteredTechnologies = [],
        status: selectedTechnologiesStatus,
        refetch: refetchAllTechnologies,
    } = useQuery<Technology[]>({
        queryKey: ['technologies', 'name', '0', searchQuery],
        queryFn: () => {
            return getTechnologiesByName(searchQuery)
        },
        staleTime: Infinity,
        enabled: searchQuery !== '',
    })
    const {
        data: allTechnologies,
        status: allTechnologiesStatus,
        fetchNextPage,
        hasNextPage,
    } = useInfiniteQuery<Technology[]>({
        queryKey: ['technologies'],
        queryFn: ({ pageParam }) => getTechnologies(pageParam as number, 10),
        staleTime: Infinity,
        getNextPageParam: (lastPage, allPages) => (lastPage.length === 10 ? allPages.length : undefined),
        initialPageParam: 0,
    })

    useEffect(() => {
        if (onInteractOutside === true) {
            onSubmit(form.getValues())
        }
    }, [onInteractOutside])

    useEffect(() => {
        if (isVisible && hasNextPage) {
            fetchNextPage()
        }
    }, [isVisible])

    const handleChange = (e: any) => {
        setSearchQuery(e.target.value)
    }

    const debouncedResults = useMemo(() => {
        return debouce(handleChange, 300)
    }, [])

    useEffect(() => {
        return () => {
            debouncedResults.cancel()
        }
    })

    useEffect(() => {
        if (searchQuery !== '') {
            refetchAllTechnologies()
        }
    }, [searchQuery])

    function onSubmit(data: z.infer<typeof FormSchema> | undefined) {
        const newValue = data && data.items && data.items.length > 0 ? data.items : undefined
        if (areObjectsEqual(newValue, value)) {
            closePopover()
            return
        }
        onChangeValue(newValue)
    }

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="grid max-w-sm items-center gap-y-4">
                <div
                    className={`relative flex flex-col items-start border rounded  max-h-40 overflow-y-auto ${selectedTechnologies.length > 0 ? 'p-2' : 'px-3'}`}
                    cmdk-input-wrapper=""
                >
                    <div className="flex flex-row gap-1 flex-wrap">
                        {selectedTechnologies.map((item: Technology) => (
                            <Tag
                                key={item.slug}
                                id={item.slug}
                                name={item.name}
                                img={item.logo || ''}
                                onClick={(slug_to_remove) => {
                                    setSelectedTechnologies(
                                        selectedTechnologies.filter((item) => item.slug !== slug_to_remove)
                                    )
                                    form.setValue(
                                        'items',
                                        selectedTechnologies
                                            .filter((item) => item.slug !== slug_to_remove)
                                            .map((item) => item.slug)
                                    )
                                }}
                            />
                        ))}
                        <input
                            type="text"
                            autoFocus={true}
                            onChange={debouncedResults}
                            placeholder="Search..."
                            className="h-8 min-w-12 rounded-md bg-transparent py-3 text-sm outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50"
                        />
                    </div>
                    {selectedTechnologies.length > 0 && (
                        <Cross2Icon
                            className="absolute right-2 top-3 h-4 w-4 cursor-pointer text-slate-500 hover:text-black"
                            onClick={() => {
                                form.setValue('items', [])
                                setSelectedTechnologies([])
                            }}
                        />
                    )}
                </div>
                <FormField
                    control={form.control}
                    name="items"
                    render={() => (
                        <FormItem>
                            <FormMessage />
                            <div className="flex flex-col max-h-[200px] overflow-scroll gap-1">
                                {((searchQuery === '' && allTechnologiesStatus === 'pending') ||
                                    (searchQuery != '' && selectedTechnologiesStatus === 'pending')) && (
                                    <div className="flex justify-center pt-2">
                                        <Spinner size="sm" />
                                    </div>
                                )}
                                {searchQuery !== '' &&
                                    filteredTechnologies.length === 0 &&
                                    selectedTechnologiesStatus === 'success' && (
                                        <div className="flex justify-center pt-2">
                                            <p className="text-sm text-gray-500 text-center">
                                                No results found for "{searchQuery}"
                                            </p>
                                        </div>
                                    )}
                                {searchQuery !== '' &&
                                    filteredTechnologies
                                        .filter(
                                            (item) =>
                                                !selectedTechnologies.some(
                                                    (technology) => technology.slug === item.slug
                                                )
                                        )
                                        .map((item) => (
                                            <FormField
                                                key={item.slug}
                                                control={form.control}
                                                name="items"
                                                render={({ field }) => {
                                                    return (
                                                        <CheckBoxFormItem key={item.slug} field={field} item={item} />
                                                    )
                                                }}
                                            />
                                        ))}
                                {searchQuery == '' && allTechnologiesStatus === 'success' && (
                                    <>
                                        {allTechnologies.pages.map((group, i) => (
                                            <React.Fragment key={i}>
                                                {group
                                                    .filter(
                                                        (item) =>
                                                            !selectedTechnologies.some(
                                                                (technology) => technology.slug === item.slug
                                                            )
                                                    )
                                                    .map((item) => (
                                                        <FormField
                                                            key={item.slug}
                                                            control={form.control}
                                                            name="items"
                                                            render={({ field }) => {
                                                                return (
                                                                    <CheckBoxFormItem
                                                                        key={item.slug}
                                                                        field={field}
                                                                        item={item}
                                                                    />
                                                                )
                                                            }}
                                                        />
                                                    ))}
                                            </React.Fragment>
                                        ))}
                                        <div ref={loadingButtonRef} className="flex justify-center">
                                            <Spinner size="sm" className="m-1" />
                                        </div>
                                    </>
                                )}
                            </div>
                        </FormItem>
                    )}
                />
                <Button type="submit" size="sm">
                    Save
                </Button>
            </form>
        </Form>
    )

    function CheckBoxFormItem({
        field,
        item,
    }: {
        field: ControllerRenderProps<{ items: string[] }, any>
        item: Technology
    }) {
        return (
            <FormItem className="flex flex-row items-center space-x-3 space-y-0">
                <FormControl>
                    <Checkbox
                        id={item.slug}
                        checked={field.value?.includes(item.slug)}
                        onCheckedChange={(checked) => {
                            if (checked && !field.value?.includes(item.slug)) {
                                setSelectedTechnologies([...selectedTechnologies, item])
                            } else {
                                setSelectedTechnologies(
                                    selectedTechnologies.filter((technology) => technology.slug !== item.slug)
                                )
                            }
                            return checked
                                ? field.onChange([...(field.value || []), item.slug])
                                : field.onChange(field.value?.filter((value: string) => value !== item.slug))
                        }}
                    />
                </FormControl>
                <FormLabel htmlFor={item.slug} className="cursor-pointer">
                    <div className="flex space-x-2 align-middle items-center">
                        {item.logo && <img src={item.logo} alt={item.name} className="w-6 h-6" />}
                        {!item.logo && (
                            <div className="w-6 h-6 bg-slate-200 flex items-center justify-center">
                                {' '}
                                <Laptop strokeWidth={1.5} />{' '}
                            </div>
                        )}
                        <div>
                            <p className="text-sm font-normal hover:underline" translate="no">
                                {' '}
                                {item.name}{' '}
                            </p>
                            <p className="text-xs font-normal text-gray-500">
                                {' '}
                                {formatNumberWithComma(item.companies || 0)} companies{' '}
                            </p>
                        </div>
                    </div>
                </FormLabel>
            </FormItem>
        )
    }
}
