import { z } from 'zod'

export const orderBySchema = z.object({
    desc: z.boolean(),
    field: z.string(),
})

export type OrderBy = z.infer<typeof orderBySchema>

export const baseSearchParamsSchema = z.object({
    page: z.number().optional().nullable(),
    limit: z.number().optional().nullable(),
    offset: z.number().optional().nullable(),
    order_by: z.array(orderBySchema).optional().nullable(),
    include_total_results: z.boolean().optional().nullable(),
    blur_company_data: z.boolean().optional().nullable(),
})
export type BaseSearchParams = z.infer<typeof baseSearchParamsSchema>
