import { PageLayout } from '@/components/ui/layout/page_layout'
import { Separator } from '@/shared/ui/base/separator'
import { Outlet } from 'react-router-dom'
import { SidebarNav } from './components/sidebar-nav'

const sidebarNavItems = [
    {
        title: 'Profile',
        href: '/settings/profile',
    },
    {
        title: 'Team',
        href: '/settings/team',
    },
    {
        title: 'Billing',
        href: '/settings/billing',
    },
    {
        title: 'API Usage and Requests',
        href: '/settings/api',
    },
    {
        title: 'API Key',
        href: '/settings/api-key',
    },
    {
        title: 'Integrations',
        href: '/settings/integrations',
    },
]

export default function SettingsLayout() {
    return (
        <PageLayout className="bg-gray-100">
            <div className="space-y-1">
                <h2 className="text-2xl font-medium tracking-tight ml-4">Settings</h2>
            </div>
            <Separator className="my-6" />
            <div className="flex flex-col gap-8 lg:flex-row lg:space-x-12 lg:space-y-0">
                <aside>
                    <SidebarNav items={sidebarNavItems} />
                </aside>
                <div className="flex-1 bg-white rounded-md p-6 border border-gray-200">
                    <Outlet />
                </div>
            </div>
        </PageLayout>
    )
}
