import { z } from 'zod'

export const teamMemberSchema = z.object({
    id: z.number(),
    first_name: z.string().nullable().optional(),
    last_name: z.string().nullable().optional(),
    email: z.string(),
})

export type TeamMember = z.infer<typeof teamMemberSchema>
