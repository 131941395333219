import { cn } from '@/lib/utils'
import { Laptop } from 'lucide-react'

function TechnologyLogo({ href, width = 10 }: { href: string | undefined | null; width?: number }) {
    return (
        <>
            {href && (
                <img
                    className={cn(`w-${width} h-${width}`, 'rounded-sm object-scale-down')}
                    src={href}
                    width="40"
                    height="40"
                    alt="Company logo"
                />
            )}
            {!href && (
                <div
                    className={cn(`w-${width} h-${width}`, 'rounded-sm bg-slate-200 flex items-center justify-center')}
                >
                    {' '}
                    <Laptop strokeWidth={1.5} />{' '}
                </div>
            )}
        </>
    )
}

export default TechnologyLogo
