import { useAuthentication } from '@/components/hooks/authentication/use-authentication'
import { useMe } from '@/components/hooks/use-me'
import { useToast } from '@/components/ui/base/use-toast'
import { ConfirmActionDialog } from '@/components/ui/dialog/confirm-action-dialog'
import { OpenDialogHandle } from '@/components/ui/dialog/dialog-handle-interface'
import { deleteUser } from '@/services/user.service'
import { Button } from '@/shared/ui/base/button'
import { Separator } from '@/shared/ui/base/separator'
import { Spinner } from '@/shared/ui/base/spinner'
import { useEffect, useRef, useState } from 'react'

function ProfilePage() {
    useEffect(() => {
        document.title = 'Profile · Settings · TheirStack'
    }, [])
    const { me } = useMe()

    const [isLoading, setIsLoading] = useState(false)
    const { logout } = useAuthentication()
    const { toast } = useToast()
    const confirmDeleteDialogRef = useRef<OpenDialogHandle>(null)

    const handleOnDeleteClick = async () => {
        if (!me) return
        setIsLoading(true)
        await deleteUser(me.id)
            .then(() => {
                logout()
            })
            .catch(() => {
                toast({ title: 'Failed to delete your account', variant: 'destructive' })
                setIsLoading(false)
            })
    }

    return (
        <div className="space-y-6">
            <div>
                <h3 className="text-lg font-medium">Profile</h3>
                <p className="text-sm text-muted-foreground">Manage your profile information.</p>
            </div>
            <Separator />
            {me && (
                <Button
                    variant="destructive"
                    onClick={() => confirmDeleteDialogRef.current?.open()}
                    disabled={isLoading}
                >
                    {!isLoading ? 'Delete Account' : <Spinner className="h-4 w-4" />}
                </Button>
            )}
            <ConfirmActionDialog
                ref={confirmDeleteDialogRef}
                title="Are you sure you want to delete your account?"
                description="This action cannot be undone and will remove all of your data from our systems."
                confirmAction={handleOnDeleteClick}
                cancelAction={() => confirmDeleteDialogRef.current?.close()}
                actionText="Delete Account"
                actionVariant="destructive"
            />
        </div>
    )
}

export default ProfilePage
