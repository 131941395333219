import { useErrorNotification } from '@/components/hooks/toast/use-error-notification'
import { useMe } from '@/components/hooks/use-me'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@/components/ui/base/dropdown-menu'
import { Input } from '@/components/ui/base/input'
import { useToast } from '@/components/ui/base/use-toast'
import { CompanyList } from '@/schemas/entities/company-list.schema'
import { User } from '@/schemas/entities/user'
import { deleteCompanyList, duplicateCompanyList, getAllCompanyLists } from '@/services/company_list.service'
import { Button } from '@/shared/ui/base/button'
import { Card } from '@/shared/ui/base/card'
import { Separator } from '@/shared/ui/base/separator'
import { HOUR_IN_MS, timeAgo } from '@/shared/utils/date-utils'
import { formatNumberWithComma } from '@/shared/utils/number-utils'
import { DotsHorizontalIcon } from '@radix-ui/react-icons'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Copy, PlusCircleIcon, Trash2 } from 'lucide-react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import CreateCompanyListButton from './button-create-company-list'

const CompanyListCard: React.FC = () => {
    const {
        data: lists = [],
        isLoading,
        isError,
    } = useQuery<CompanyList[]>({
        queryKey: ['company-list'],
        queryFn: getAllCompanyLists,
        staleTime: HOUR_IN_MS,
    })
    const [searchKeywords, setSearchKeywords] = useState<string>('')
    useErrorNotification({ isError })
    const { me } = useMe()

    return (
        <div className="flex flex-col items-start space-y-6 w-full h-full">
            <div className="flex flex-row justify-between w-full space-x-20">
                <Input
                    placeholder="Filter by name..."
                    onChange={(event) => setSearchKeywords(event.target.value)}
                    className="h-9 max-w-60"
                />
                <CreateCompanyListButton>
                    <Button size="sm" variant="secondary">
                        <PlusCircleIcon className="mr-2 h-4 w-4" /> New list
                    </Button>
                </CreateCompanyListButton>
            </div>
            {isLoading ||
                (!me && (
                    <div className="flex flex-col space-y-2 w-full overflow-y-auto max-h-96">
                        {[...Array(3)].map((_, i) => (
                            <Card
                                key={i}
                                className="w-full flex flex-row p-2 space-x-2 items-center justify-between h-10 bg-gray-200 animate-pulse"
                            />
                        ))}
                    </div>
                ))}
            {!isLoading && me && (
                <div className="flex flex-col w-full overflow-y-auto max-h-96 relative">
                    {lists
                        .filter((list) =>
                            searchKeywords ? list.name.toLowerCase().includes(searchKeywords.toLowerCase()) : true
                        )
                        .map((list, index) => (
                            <div key={list.id}>
                                <Row key={list.id} list={list} me={me} />
                                {index !== lists.length - 1 && <Separator className="mx-1" />}
                            </div>
                        ))}
                </div>
            )}
        </div>
    )
}

export default CompanyListCard

function Row({ list, me }: { list: CompanyList; me: User }) {
    return (
        <div className="flex flex-row p-2 justify-between items-center w-full hover:bg-gray-50">
            <Link to={`/company-list/${list.id}`}>
                <div className="flex flex-col space-y-1">
                    <p className="text-sm"> {list.name}</p>
                    <p className="text-xs text-gray-400">
                        {formatNumberWithComma(list.companies_count)} companies · Updated {timeAgo(list.created_at)}{' '}
                    </p>
                </div>
            </Link>
            <CardActions list={list} me={me} />
        </div>
    )
}

function CardActions({ list, me }: { list: CompanyList; me: User }) {
    const queryClient = useQueryClient()
    const { toast } = useToast()

    const handleDelete = () => {
        deleteCompanyList(list.id).then(() => {
            toast({ title: 'List deleted' })
            queryClient.invalidateQueries({ queryKey: ['company-list'] })
        })
    }

    const handleDuplicate = () => {
        duplicateCompanyList(list.id).then(() => {
            toast({ title: 'List duplicated' })
            queryClient.invalidateQueries({ queryKey: ['company-list'] })
        })
    }

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="flex h-8 w-8 p-0 data-[state=open]:bg-muted">
                    <DotsHorizontalIcon className="h-4 w-4" />
                    <span className="sr-only">Open menu</span>
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="w-[160px]">
                <DropdownMenuItem onClick={handleDelete} disabled={me.id != list.user_id || !list.deletable}>
                    <Trash2 className="mr-2 h-4 w-4" /> Delete
                </DropdownMenuItem>
                <DropdownMenuItem onClick={handleDuplicate}>
                    <Copy className="mr-2 h-4 w-4" /> Duplicate
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    )
}
