import { Plan, isCancelationAvailable } from '@/schemas/entities/user'
import { getBillingPortalSessionUrl } from '@/services/user.service'
import { Button } from '@/shared/ui/base/button'
import { Card } from '@/shared/ui/base/card'
import { formatDateTimeStringToReadableDate } from '@/shared/utils/date-utils'
import { formatNumberWithComma } from '@/shared/utils/number-utils'

export function PlanCard({
    plan,
    isCancellationLoading,
    setShowCancelDialog,
}: {
    plan: Plan
    isCancellationLoading: boolean
    setShowCancelDialog: (show: boolean) => void
}) {
    const getRemainingDays = (plan: Plan) => {
        if (!plan.current_period_end) return 0
        const currentDate = new Date()
        const currentPeriodEnd = new Date(plan.current_period_end)
        return Math.ceil((currentPeriodEnd.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24))
    }

    return (
        <Card key={plan.name} className="flex flex-row gap-1 p-2 items-center justify-between">
            <div className="flex flex-col gap-2 items-start justify-start">
                <p className="text-md font-medium ">{plan.name} plan</p>

                <span className="text-xs text-muted-foreground">
                    {formatDateTimeStringToReadableDate(plan.current_period_start ?? '')} -{' '}
                    {formatDateTimeStringToReadableDate(plan.current_period_end ?? '')}
                    {plan.recurring && ` (renews in ${getRemainingDays(plan)} days)`}
                </span>

                <div className="flex flex-row gap-1 items-center justify-start">
                    <p className="text-xs">
                        {plan.credits_used_current_period != null && (
                            <span>{formatNumberWithComma(plan.credits_used_current_period)} used of </span>
                        )}
                        {formatNumberWithComma(plan.credits)} company credits {plan.recurring ? '/ month' : ''}
                    </p>
                </div>
                {plan.api_credits > 0 && (
                    <div className="flex flex-row gap-1 items-center justify-start">
                        <p className="text-xs">
                            {plan.api_credits_used_current_period != null && (
                                <span>{formatNumberWithComma(plan.api_credits_used_current_period)} used of </span>
                            )}
                            <span>
                                {formatNumberWithComma(plan.api_credits)} API credits {plan.recurring ? '/ month' : ''}
                            </span>
                        </p>
                    </div>
                )}

                {!isCancellationLoading && plan.cancel_at_period_end == true && (
                    <p className="text-sm text-muted-foreground">
                        This plan is set to cancel at the end of the billing period.
                    </p>
                )}
            </div>
            <div className="flex flex-row gap-2 items-center justify-start">
                {plan.name != 'Free' && plan.recurring && (
                    <Button
                        variant="outline"
                        onClick={async () => {
                            const url = await getBillingPortalSessionUrl()
                            window.open(url)
                        }}
                    >
                        Upgrade plan
                    </Button>
                )}
                {!isCancellationLoading && isCancelationAvailable(plan) && (
                    <Button
                        variant="outline"
                        className="border-red-500 text-red-500 hover:bg-red-500 hover:text-white"
                        onClick={() => setShowCancelDialog(true)}
                    >
                        Cancel plan
                    </Button>
                )}
            </div>
        </Card>
    )
}
