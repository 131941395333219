import { useRevealCompany } from '@/components/hooks/company/use-reveal-company'
import { ConfirmRevealCompanyDialog } from '@/components/ui/dialog/confirm-reveal-company-dialog'
import { OpenDialogHandle } from '@/components/ui/dialog/dialog-handle-interface'
import OutOfCreditsDialog from '@/components/ui/dialog/out-of-credits-dialog'
import { FullCompany } from '@/schemas/entities/full-company.schema'
import { CompaniesSearchParams } from '@/schemas/request/companies-search-params.schema'
import { Card } from '@/shared/ui/base/card'
import { formatNumberWithComma } from '@/shared/utils/number-utils'
import { BriefcaseBusiness } from 'lucide-react'
import { useRef } from 'react'

function getJobsMathingFiltersFromCompanyLink(company_name: string, searchPams: CompaniesSearchParams) {
    return (
        '/search/jobs/new?query=' +
        btoa(
            encodeURIComponent(
                JSON.stringify({
                    name: 'Jobs',
                    auto_search: true,
                    query: {
                        page: 0,
                        limit: 10,
                        company_name_or: [company_name],
                        posted_at_max_age_days: 365,
                        order_by: [
                            {
                                field: 'date_posted',
                                desc: true,
                            },
                        ],
                        ...searchPams.job_filters,
                    },
                })
            )
        )
    )
}

export default function JobMatchingFiltersColumn({
    company,
    searchPams: searchParams,
    updateCompany,
}: {
    company: FullCompany
    searchPams: CompaniesSearchParams
    updateCompany: (company: FullCompany) => void
}) {
    const { revealCompany: revealCompanyAndOpenNewTab } = useRevealCompany({
        company,
        updateCompany,
        postRevealAction: (company) =>
            window.open(getJobsMathingFiltersFromCompanyLink(company.name, searchParams), '_blank'),
        showUpgradeDialog: () => outOfCreditsDialogRef.current?.open(),
    })
    const outOfCreditsDialogRef = useRef<OpenDialogHandle>(null)
    const n_jobs_found = company.jobs_found?.length || 0
    return (
        <div>
            {n_jobs_found > 0 && (
                <div className="flex items-center space-x-1 align-middle">
                    {company.jobs_found?.slice(0, 1).map((job) => (
                        <div key={job.id}>
                            {company.has_blurred_data == false && (
                                <a
                                    key={job.id}
                                    href={getJobsMathingFiltersFromCompanyLink(company.name, searchParams)}
                                    target="_blank"
                                >
                                    <Card className="p-1 hover:bg-gray-100 cursor-pointer">
                                        <div className="flex items-center space-x-1">
                                            <div className="h-4 w-4">
                                                <div className="w-4 h-4 flex items-center justify-center">
                                                    {' '}
                                                    <BriefcaseBusiness strokeWidth={1.5} />{' '}
                                                </div>
                                            </div>
                                            <p className="text-sm font-normal whitespace-nowrap">{job.job_title}</p>
                                        </div>
                                    </Card>
                                </a>
                            )}
                            {company.has_blurred_data == true && (
                                <ConfirmRevealCompanyDialog confirmAction={revealCompanyAndOpenNewTab} n_companies={1}>
                                    <Card className="p-1 hover:bg-gray-100 cursor-pointer">
                                        <div className="flex items-center space-x-1">
                                            <div className="h-4 w-4">
                                                <div className="w-4 h-4 flex items-center justify-center">
                                                    {' '}
                                                    <BriefcaseBusiness strokeWidth={1.5} />{' '}
                                                </div>
                                            </div>
                                            <p className="text-sm font-normal whitespace-nowrap">{job.job_title}</p>
                                        </div>
                                    </Card>
                                </ConfirmRevealCompanyDialog>
                            )}
                        </div>
                    ))}
                    {n_jobs_found - 1 > 1 && (
                        <>
                            {company.has_blurred_data == false && (
                                <a
                                    href={getJobsMathingFiltersFromCompanyLink(company.name, searchParams)}
                                    target="_blank"
                                >
                                    <Card className="p-1 hover:bg-gray-100 cursor-pointer">
                                        <p className="font-normal text-sm whitespace-nowrap">
                                            {' '}
                                            + {formatNumberWithComma(n_jobs_found - 1)}{' '}
                                        </p>
                                    </Card>
                                </a>
                            )}
                            {company.has_blurred_data == true && (
                                <ConfirmRevealCompanyDialog confirmAction={revealCompanyAndOpenNewTab} n_companies={1}>
                                    <Card className="p-1 hover:bg-gray-100 cursor-pointer">
                                        <p className="font-normal text-sm whitespace-nowrap">
                                            {' '}
                                            + {formatNumberWithComma(n_jobs_found - 1)}{' '}
                                        </p>
                                    </Card>
                                </ConfirmRevealCompanyDialog>
                            )}
                        </>
                    )}
                </div>
            )}
            <OutOfCreditsDialog ref={outOfCreditsDialogRef} />
        </div>
    )
}
