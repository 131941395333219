import { truncateLongValues } from './excel-utils'

export function download_csv(data: string[][], filename: string) {
    const csvLines = truncateLongValues(data)
    const csvContent = csvLines.map((e) => e.map((field) => `"${field}"`).join(',')).join('\n')

    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })

    // Create a temporary anchor element
    const link = document.createElement('a')
    const url = URL.createObjectURL(blob)
    link.setAttribute('href', url)
    link.setAttribute('download', filename)

    // Append the anchor to the document body and trigger the download
    document.body.appendChild(link)
    link.click()

    // Clean up by revoking the object URL and removing the anchor
    URL.revokeObjectURL(url)
    document.body.removeChild(link)
}
