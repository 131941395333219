import { useEffect, useState } from 'react'

export function useSavedSearch<TSavedSearch>(
    searchId: string | undefined,
    fetchSearch: (id: number) => Promise<TSavedSearch>
) {
    const [search, setSearch] = useState<TSavedSearch>()
    const [isFetching, setIsFetching] = useState(true)

    useEffect(() => {
        if (!searchId) return
        setIsFetching(true)
        fetchSearch(+searchId)
            .then((search) => {
                setSearch(search)
            })
            .finally(() => {
                setIsFetching(false)
            })
    }, [searchId])

    return { search, setSearch, isFetching }
}
