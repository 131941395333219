import { z } from 'zod'

export const companySchema = z.object({
    id: z.string(),
    name: z.string(),
    logo: z.string().nullable().optional(),
    url: z.string().nullable().optional(),
    domain: z.string().nullable().optional(),
    has_blurred_data: z.boolean().nullable().optional(),
    long_description: z.string().nullable().optional(),
    seo_description: z.string().nullable().optional(),
    industry: z.string().nullable().optional(),
    country: z.string().nullable().optional(),
    country_code: z.string().nullable().optional(),
    city: z.string().nullable().optional(),
    employee_count: z.number().nullable().optional(),
    possible_domains: z.array(z.string()).nullable().optional(),
    apollo_id: z.string().nullable().optional(),
    linkedin_id: z.string().nullable().optional(),
    linkedin_url: z.string().nullable().optional(),
    num_jobs: z.number().nullable().optional(),
    total_funding_usd: z.number().nullable().optional(),
    annual_revenue_usd: z.number().nullable().optional(),
    last_funding_round_date: z.string().nullable().optional(),
    technology_slugs: z.array(z.string()).nullable().optional(),
    founded_year: z.number().nullable().optional(),
    funding_stage: z.string().nullable().optional(),
    company_keywords: z.array(z.string()).nullable().optional(),
})

export type Company = z.infer<typeof companySchema>
