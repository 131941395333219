import { cn } from '@/lib/utils'
import { WithTooltip } from '@/shared/ui/base/tooltip'
import { formatNumberWithComma } from '@/shared/utils/number-utils'
import { cva, type VariantProps } from 'class-variance-authority'
import CreditsIcon from './credits-icon'

const badgeVariants = cva(
    'inline-grid grid-flow-col justify-start items-center  rounded-md px-1 py-0.5 cursor-pointer',
    {
        variants: {
            color: {
                green: 'bg-green-200 text-green-800',
                blue: 'bg-blue-200 text-blue-800',
                gray: 'bg-gray-200 text-gray-700',
            },
            size: {
                sm: 'px-1 py-0.5 gap-0.5',
                base: 'px-2 py-1 gap-1',
            },
        },
        defaultVariants: {
            size: 'base',
        },
    }
)

interface CreditsBadgeProps extends VariantProps<typeof badgeVariants> {
    credits: number
    type?: 'company' | 'API'
}

export default function CreditsBadge({ credits, type = 'company', size }: CreditsBadgeProps) {
    return (
        <WithTooltip title={`This action costs ${formatNumberWithComma(credits)} ${type} credits.`}>
            <div
                className={cn(
                    badgeVariants({
                        size,
                        color: credits == 0 ? 'gray' : type == 'API' ? 'blue' : 'green',
                    })
                )}
            >
                <span className="text-xs font-medium">{formatNumberWithComma(credits)}</span>
                <CreditsIcon className="w-4 h-4" />
            </div>
        </WithTooltip>
    )
}
