import { AlertCircle, Rocket } from 'lucide-react'
import { Dialog, DialogContent, DialogTitle, DialogTrigger } from '@/shared/ui/base/dialog'
import { Button } from '../../../shared/ui/base/button'
import PricingGrid from '../layout/components/pricing_grid'
import * as VisuallyHidden from '@radix-ui/react-visually-hidden'

function OutOfCreditsBanner({ hidden_results }: { hidden_results: number }) {
    return (
        <div className="h-full flex items-center justify-between space-x-4 py-4 px-8  md:flex bg-orange-100 rounded-lg">
            <div className="flex items-center justify-start space-x-4">
                <AlertCircle className="h-6 w-6 text-orange-800 inline " />
                <div>
                    <p className="text-sm text-orange-800 font-bold  "> You've reached your account limit</p>
                    <p className="text-sm text-orange-800  font-normal">
                        {hidden_results} records of this page are hidden, please upgrade your plan to see more results.
                    </p>
                </div>
            </div>
            <Dialog>
                <DialogTrigger asChild>
                    <Button className="text-orange-800" variant="outline">
                        <Rocket className="w-5 h-5 mr-2" /> Upgrade{' '}
                    </Button>
                </DialogTrigger>
                <DialogContent className="sm:max-w-[1225px]" aria-describedby={undefined}>
                    <VisuallyHidden.Root asChild>
                        <DialogTitle>Pricing</DialogTitle>
                    </VisuallyHidden.Root>
                    <PricingGrid />
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default OutOfCreditsBanner
