import { TechnologyDetaislFound } from '@/schemas/entities/full-company.schema'
import { Card } from '@/shared/ui/base/card'
import { ConfidenceLevelChart } from '@/shared/ui/confidence-level-chart'
import { Laptop } from 'lucide-react'

export default function TechnologyCard({ techdetails }: { techdetails: TechnologyDetaislFound }) {
    return (
        <Card className="p-1 cursor-pointer hover:bg-gray-200">
            <div className="flex items-center space-x-1">
                <div className="h-6 w-6">
                    {techdetails.technology.logo && (
                        <img
                            src={techdetails.technology.logo}
                            alt={techdetails.technology.name}
                            className="w-6 h-6 rounded-md"
                        />
                    )}
                    {!techdetails.technology.logo && (
                        <div className="w-6 h-6 bg-slate-200 flex items-center justify-center">
                            {' '}
                            <Laptop strokeWidth={1.5} />{' '}
                        </div>
                    )}
                </div>
                <p className="text-sm font-normal whitespace-nowrap" translate="no">
                    {techdetails.technology.name}
                </p>
                <ConfidenceLevelChart confidence={techdetails.confidence} />
            </div>
        </Card>
    )
}
