'use client'

import { CircleHelp } from 'lucide-react'
import { Popover, PopoverContent, PopoverTrigger } from '../base/popover'
import { Button } from '../base/button'
import { useState } from 'react'

export default function HelpCreditTooltip({ children }: { children: React.ReactNode }) {
    const [isOpen, setIsOpen] = useState(false)

    const handleMouseEnter = () => {
        setIsOpen(true)
    }

    const handleMouseLeave = () => {
        setIsOpen(false)
    }

    return (
        <Popover open={isOpen} onOpenChange={setIsOpen}>
            <PopoverTrigger onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} asChild>
                <Button variant="ghost" size="icon_xs">
                    <CircleHelp className="w-4 h-4" />
                </Button>
            </PopoverTrigger>
            <PopoverContent onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                {children}
            </PopoverContent>
        </Popover>
    )
}
