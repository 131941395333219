import {
    AlertDialog,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '@/components/ui/base/alert-dialog'
import { Button, ButtonProps } from '@/shared/ui/base/button'
import { forwardRef, useImperativeHandle, useState } from 'react'
import { OpenDialogHandle } from './dialog-handle-interface'

export interface ConfirmActionDialogProps {
    title: string
    description?: string
    actionText?: string
    actionVariant?: ButtonProps['variant']
    confirmAction: () => void
    cancelAction?: () => void
    children?: React.ReactNode
    defaultOpen?: boolean
}

export const ConfirmActionDialog = forwardRef<OpenDialogHandle, ConfirmActionDialogProps>(
    (
        {
            title,
            description,
            actionText = 'Continue',
            actionVariant = 'default',
            confirmAction,
            children,
            cancelAction = () => {},
            defaultOpen = false,
        },
        ref
    ) => {
        const [show, setShow] = useState(defaultOpen)
        useImperativeHandle(ref, () => ({
            open: () => setShow(true),
            close: () => setShow(false),
        }))

        return (
            <AlertDialog open={show} onOpenChange={(open) => setShow(open)}>
                {children && <AlertDialogTrigger asChild>{children}</AlertDialogTrigger>}
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <AlertDialogTitle>{title}</AlertDialogTitle>
                        <AlertDialogDescription>{description}</AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                        <Button variant="outline" onClick={cancelAction}>
                            Cancel
                        </Button>
                        <Button variant={actionVariant} onClick={confirmAction}>
                            {actionText}
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        )
    }
)
