import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@/components/ui/base/dropdown-menu'
import { get_search_url } from '@/pages/home/components/saved-searches/recent-searches-card'
import { SearchType } from '@/schemas/entities/recent-search.schema'
import { CompaniesSearchParams } from '@/schemas/request/companies-search-params.schema'
import { JobsSearchParams } from '@/schemas/request/jobs-search-params.schema'
import { Button } from '@/shared/ui/base/button'
import { Ellipsis, Share2 } from 'lucide-react'
import { useToast } from '../../base/use-toast'

export default function OtherActions({
    search_type,
    searchParams,
}: {
    search_type: SearchType
    searchParams: JobsSearchParams | CompaniesSearchParams
}) {
    const { toast } = useToast()
    return (
        <>
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <Button variant="ghost" size="sm" className="text-sm font-normal">
                        <Ellipsis className="h-4 w-4" />
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="start">
                    <DropdownMenuItem
                        onSelect={() => {
                            navigator.clipboard.writeText(
                                import.meta.env.VITE_REACT_APP_API_SERVER_URL +
                                    get_search_url(search_type, searchParams)
                            )
                            toast({
                                title: 'Copied to clipboard',
                                description: 'The search URL has been copied to your clipboard.',
                            })
                        }}
                    >
                        <Share2 className="mr-2 h-4 w-4" /> Share search
                    </DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>
        </>
    )
}
