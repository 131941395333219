import { useForm } from 'react-hook-form'
import { Input } from '@/components/ui/base/input'
import { Button } from '@/shared/ui/base/button'
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/ui/base/form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { formatNumberWithComma } from '@/shared/utils/number-utils'

const formSchema = z.object({
    numberField: z.coerce.number().min(1, 'Value must be greater than 0'),
})

export function SearchFilterValuePreviewNumber({ value }: { value: number }) {
    return <p className="text-sm">{formatNumberWithComma(value)}</p>
}

export function SearchFilterFormNumber({
    value,
    onChangeValue,
    onInteractOutside,
    closePopover,
}: {
    id: string
    value: number
    onChangeValue: (_value: number) => void
    onInteractOutside: boolean
    closePopover: () => void
}) {
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            numberField: value || 5,
        },
    })

    useEffect(() => {
        if (onInteractOutside === true) {
            onSubmit(form.getValues())
        }
    }, [onInteractOutside])

    function onSubmit(values: z.infer<typeof formSchema>) {
        const newValue = values.numberField
        if (newValue === value) {
            closePopover()
            return
        }
        onChangeValue(newValue)
    }

    return (
        <>
            <Form {...form}>
                <form className="grid  max-w-sm items-center gap-y-4" onSubmit={form.handleSubmit(onSubmit)}>
                    <FormField
                        control={form.control}
                        name="numberField"
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input autoFocus={true} type="number" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <Button type="submit" size="sm">
                        Save
                    </Button>
                </form>
            </Form>
        </>
    )
}
