import { useFetchDataForTable } from '@/pages/company-list/[id]/components/use-fetch-data-for-table'
import { CreditsConsumption } from '@/schemas/entities/credits-consumption.schema'
import { Request } from '@/schemas/entities/recent-search.schema'
import { RequestCount } from '@/schemas/entities/request-count.schema'
import { RequestSearchParams } from '@/schemas/request/request-search-params.schema'
import { getCreditsConsumption } from '@/services/billing.service'
import { getRequestCount, getRequests } from '@/services/requests.service'
import { Separator } from '@/shared/ui/base/separator'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { DateRange } from 'react-day-picker'
import { APIRequestChart } from './components/api-consumption.chart'
import { DatePickerWithRange } from './components/data-range-picker'
import { DataTableWithPagination } from './components/data-table'
import { columns } from './components/data-table-columns'
import { APIRequestCountChart } from './components/request-count.chart'

export default function APIRequestTable({
    currentPeriodStart,
    currentPeriodEnd,
}: {
    currentPeriodStart: Date
    currentPeriodEnd: Date
}) {
    const [searchParams, setSearchParams] = useState<RequestSearchParams>({
        start_datetime: currentPeriodStart,
        end_datetime: currentPeriodEnd,
    })

    const { table, isDataFetching, onChangeFilters } = useFetchDataForTable<RequestSearchParams, Request>(
        searchParams,
        getRequests,
        columns,
        10000
    )

    const { data: creditsConsumption } = useQuery<CreditsConsumption[]>({
        queryKey: ['credits-consumption', searchParams.start_datetime, searchParams.end_datetime],
        queryFn: () => getCreditsConsumption(searchParams.start_datetime, searchParams.end_datetime),
    })

    const { data: requestCount } = useQuery<RequestCount[]>({
        queryKey: ['request-count', searchParams.start_datetime, searchParams.end_datetime],
        queryFn: () => getRequestCount(searchParams.start_datetime, searchParams.end_datetime),
    })

    const onChangeDate = (date: DateRange | undefined) => {
        if (date?.from && date?.to) {
            setSearchParams({ start_datetime: date.from, end_datetime: date.to })
            onChangeFilters({ start_datetime: date.from, end_datetime: date.to })
        }
    }

    return (
        <>
            <div className="space-y-6">
                <div className="flex justify-between">
                    <DatePickerWithRange
                        defaultDate={{ from: currentPeriodStart, to: currentPeriodEnd }}
                        defaultLabel="Current Billing Cycle"
                        onChangeDate={onChangeDate}
                    />
                </div>
                <div className="space-y-3">
                    <APIRequestChart data={creditsConsumption} />
                </div>
            </div>

            <div className="space-y-6 mt-8">
                <div>
                    <h3 className="text-lg font-medium">API Requests</h3>
                    <p className="text-sm text-muted-foreground">View all API requests for your account.</p>
                </div>
                <Separator />

                <div className="space-y-3">
                    <APIRequestCountChart data={requestCount} />
                </div>

                <DataTableWithPagination
                    table={table}
                    columns={columns}
                    isDataFetching={isDataFetching}
                    totalResults={0}
                />
            </div>
        </>
    )
}
