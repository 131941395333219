import { useRevealCompany } from '@/components/hooks/company/use-reveal-company'
import { OpenDialogHandle } from '@/components/ui/dialog/dialog-handle-interface'
import OutOfCreditsDialog from '@/components/ui/dialog/out-of-credits-dialog'
import RevealButton from '@/components/ui/reveal-button'
import { FullCompany } from '@/schemas/entities/full-company.schema'
import CreditsActionTooltip from '@/shared/ui/credits/credits-action-tooltip'
import { useRef } from 'react'

export default function RevealCompanyButton({
    company,
    updateCompany,
}: {
    company: FullCompany
    updateCompany: (company: FullCompany) => void
}) {
    const outOfCreditsDialogRef = useRef<OpenDialogHandle>(null)
    const { isDataFetching, revealCompany } = useRevealCompany({
        company,
        updateCompany,
        showUpgradeDialog: () => outOfCreditsDialogRef.current?.open(),
    })
    return (
        <>
            {company.has_blurred_data && (
                <CreditsActionTooltip credits={1} object_name="company">
                    <RevealButton isDataFetching={isDataFetching} revealCompany={revealCompany} />
                </CreditsActionTooltip>
            )}
            <OutOfCreditsDialog ref={outOfCreditsDialogRef} />
        </>
    )
}
