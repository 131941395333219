export const fromCurlToN8N = (curl: string) => {
    // Parse the curl command to extract components
    const headers =
        curl.match(/--header\s+"([^"]+)"/g)?.map((h) => {
            const [name, value] = h.match(/--header\s+"([^:]+):\s*([^"]+)"/)?.slice(1) ?? []
            return { name, value }
        }) ?? []

    // Extract URL
    const url = curl.match(/--url\s+"([^"]+)"/)?.[1]

    // Extract data payload
    const dataMatch = curl.match(/-d\s+'({[^']+})'/)?.[1]
    const data = dataMatch ? JSON.parse(dataMatch.replace(/\n\s*/g, '')) : null

    // Construct the new n8n format
    const output = {
        nodes: [
            {
                parameters: {
                    method: 'POST',
                    url,
                    sendHeaders: headers.length > 0,
                    headerParameters: {
                        parameters: headers.map((h) => ({
                            name: h.name,
                            value: h.value,
                        })),
                    },
                    sendBody: !!data,
                    specifyBody: 'json',
                    jsonBody: data ? JSON.stringify(data, null, 2) : '',
                    options: {},
                },
                type: 'n8n-nodes-base.httpRequest',
                typeVersion: 4.2,
                position: [220, 0],
                id: crypto.randomUUID?.() ?? '0a760f83-fc94-4c8f-babe-dc6401711b25',
                name: 'Theirstack API Request',
            },
        ],
        connections: {},
        pinData: {},
    }

    return JSON.stringify(output)
}
