import { useRevealJob } from '@/components/hooks/job/use-reveal-job'
import { OpenDialogHandle } from '@/components/ui/dialog/dialog-handle-interface'
import OutOfCreditsDialog from '@/components/ui/dialog/out-of-credits-dialog'
import RevealButton from '@/components/ui/reveal-button'
import { Job } from '@/schemas/entities/job.schema'
import CreditsActionTooltip from '@/shared/ui/credits/credits-action-tooltip'
import { useRef } from 'react'

export default function RevealJobButton({ job, updateRow }: { job: Job; updateRow: (job: Job) => void }) {
    const outOfCreditsDialogRef = useRef<OpenDialogHandle>(null)
    const { isDataFetching, revealJob } = useRevealJob({
        job,
        updateRow,
        showUpgradeDialog: () => outOfCreditsDialogRef.current?.open(),
    })
    return (
        <>
            {job.has_blurred_data && (
                <CreditsActionTooltip credits={1} object_name="job">
                    <RevealButton isDataFetching={isDataFetching} revealCompany={revealJob} />
                </CreditsActionTooltip>
            )}
            <OutOfCreditsDialog ref={outOfCreditsDialogRef} />
        </>
    )
}
