import { ListPlusIcon } from 'lucide-react'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from '@/components/ui/base/dropdown-menu'
import { Table } from '@tanstack/react-table'
import { Button } from '@/shared/ui/base/button'
import { WithTooltip } from '../../../../shared/ui/base/tooltip'
import { addCompanyToCompanyList, getAllCompanyLists } from '@/services/company_list.service'
import { useToast } from '../../base/use-toast'
import { formatNumberWithComma } from '@/shared/utils/number-utils'
import { CompanyList } from '@/schemas/entities/company-list.schema'
import { useQuery } from '@tanstack/react-query'
import { HOUR_IN_MS } from '@/shared/utils/date-utils'
import { Company } from '@/shared/schemas/entity/company.schema'
import CreateCompanyListButton from '@/pages/home/components/company-list/button-create-company-list'
import { useCompanyAction } from '@/components/hooks/data-table/use-company-action'

export default function AddCompanyToList<TData>({
    isDataFetching,
    table,
    fromRowToCompany,
}: {
    isDataFetching: boolean
    table: Table<TData>
    fromRowToCompany: (rows: TData[]) => Company[]
}) {
    const { isActionDisabled, disableReason, companies } = useCompanyAction(isDataFetching, table, fromRowToCompany)
    const { data: lists = [], refetch } = useQuery<CompanyList[]>({
        queryKey: ['company-list'],
        queryFn: getAllCompanyLists,
        staleTime: HOUR_IN_MS,
    })
    const { toast } = useToast()

    const addToList = (listId: number) => {
        addCompanyToCompanyList(
            listId,
            companies.map((x) => x.name).filter((x) => x != null && x != '')
        ).then(() => {
            refetch()
            toast({ title: 'Companies added to list' })
        })
    }
    const button = (
        <Button variant="ghost" size="sm" className="text-sm font-normal" disabled={isActionDisabled}>
            <ListPlusIcon className="h-4 w-4 md:mr-2" />
            <p className="hidden lg:inline"> Add to list </p>
        </Button>
    )
    return (
        <>
            {isActionDisabled && (
                <WithTooltip title="Add companies to list" message={disableReason}>
                    {' '}
                    {button}{' '}
                </WithTooltip>
            )}
            {!isActionDisabled && (
                <DropdownMenu>
                    <WithTooltip title="Add companies to list">
                        <DropdownMenuTrigger asChild>{button}</DropdownMenuTrigger>
                    </WithTooltip>
                    <DropdownMenuContent align="start" className="w-[300px]">
                        <DropdownMenuLabel>Add companies to list ({companies.length} companies)</DropdownMenuLabel>
                        <DropdownMenuSeparator />
                        {lists.map((list) => (
                            <DropdownMenuItem
                                key={list.id}
                                onSelect={() => addToList(list.id)}
                                disabled={list.name == 'Companies seen' || companies.length === 0}
                            >
                                {list.name} ({formatNumberWithComma(list.companies_count)})
                            </DropdownMenuItem>
                        ))}
                        <CreateCompanyListButton onCreate={(id: number) => addToList(id)}>
                            <a className="text-gray-600 cursor-pointer flex flex-row items-center px-2 py-1.5 underline">
                                <p className="text-sm">Create new list</p>
                            </a>
                        </CreateCompanyListButton>
                    </DropdownMenuContent>
                </DropdownMenu>
            )}
        </>
    )
}
