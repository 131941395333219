import { getImpersonatingToken, getToken } from '@/services/auth/auth.service'

export const HEADERS = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
}

export const getHTTPHeadersImpersonating = () => {
    const token_impersonate = getImpersonatingToken()
    if (token_impersonate) {
        return {
            ...HEADERS,
            ...{ Authorization: 'Bearer ' + token_impersonate },
        }
    }
    return { ...HEADERS, ...{ Authorization: 'Bearer ' + getToken() } }
}

export const getHTTPHeaders = () => {
    return { ...HEADERS, ...{ Authorization: 'Bearer ' + getToken() } }
}

export const getHTTPHeadersWithTokenPlaceholder = () => {
    return { ...HEADERS, ...{ Authorization: 'Bearer <api_key>' } }
}
