import { ChevronLeftIcon, ChevronRightIcon, DoubleArrowLeftIcon, DoubleArrowRightIcon } from '@radix-ui/react-icons'
import { Table } from '@tanstack/react-table'

import { Button } from '@/shared/ui/base/button'
import { Spinner } from '@/shared/ui/base/spinner'
import { formatNumberWithComma } from '@/shared/utils/number-utils'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../base/select'

const PAGINATION_OPTIONS = [25, 100, 200]
interface DataTablePaginationProps<TData> {
    table: Table<TData>
    totalResults: number
}

export function DataTablePagination<TData>({ table, totalResults }: DataTablePaginationProps<TData>) {
    const getFirstRowIndex = () => table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1
    const is_bulk_selection = (table.options.meta?.getBulkSelectionData().length || 0) > 0
    const is_default_selection = table.getFilteredSelectedRowModel().rows.length > 0

    return (
        <div className="flex items-center flex-wrap lg:justify-between gap-2 px-2">
            {is_default_selection && !is_bulk_selection && (
                <div className="flex-1 text-sm text-muted-foreground">
                    <p className="inline">{formatNumberWithComma(table.getFilteredSelectedRowModel().rows.length)}</p>
                    <p className="hidden md:inline"> of {table.getFilteredRowModel().rows.length}</p>
                    <p className="inline"> row(s) selected.</p>
                </div>
            )}
            {is_bulk_selection && (
                <div className="flex-1 text-sm text-muted-foreground">
                    {formatNumberWithComma(table.options.meta?.getBulkSelectionData()?.length)} row(s) selected.
                </div>
            )}
            {!is_default_selection && !is_bulk_selection && (
                <div className="flex-1 text-sm text-muted-foreground flex space-x-1">
                    <p className="hidden md:inline">
                        {' '}
                        Showing {getFirstRowIndex()}-{getFirstRowIndex() + table.getRowModel().rows.length - 1} of{' '}
                    </p>
                    {totalResults > -1 ? <p>{formatNumberWithComma(totalResults)}</p> : <Spinner size="sm" />}
                    <p>results</p>
                </div>
            )}

            <div className="flex items-center gap-2 lg:gap-8 flex-wrap">
                {PAGINATION_OPTIONS.includes(table.getState().pagination.pageSize) && (
                    <div className="hidden md:flex items-center space-x-2">
                        <p className="text-sm font-medium">Rows per page</p>
                        <Select
                            value={`${table.getState().pagination.pageSize}`}
                            onValueChange={(value) => {
                                table.setPageSize(Number(value))
                            }}
                        >
                            <SelectTrigger className="h-8 w-[70px]">
                                <SelectValue placeholder={table.getState().pagination.pageSize} />
                            </SelectTrigger>
                            <SelectContent side="top">
                                {PAGINATION_OPTIONS.map((pageSize) => (
                                    <SelectItem key={pageSize} value={`${pageSize}`}>
                                        {pageSize}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                )}
                <div className="flex items-center gap-2 ">
                    <p className="text-sm font-medium">
                        {' '}
                        Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
                    </p>
                    <Button
                        variant="outline"
                        className="hidden h-8 w-8 p-0 lg:flex"
                        onClick={() => table.setPageIndex(0)}
                        disabled={!table.getCanPreviousPage()}
                    >
                        <span className="sr-only">Go to first page</span>
                        <DoubleArrowLeftIcon className="h-4 w-4" />
                    </Button>
                    <Button
                        variant="outline"
                        className="h-8 w-8 p-0"
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                    >
                        <span className="sr-only">Go to previous page</span>
                        <ChevronLeftIcon className="h-4 w-4" />
                    </Button>
                    <Button
                        variant="outline"
                        className="h-8 w-8 p-0"
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                    >
                        <span className="sr-only">Go to next page</span>
                        <ChevronRightIcon className="h-4 w-4" />
                    </Button>
                    <Button
                        variant="outline"
                        className="hidden h-8 w-8 p-0 lg:flex"
                        onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                        disabled={!table.getCanNextPage()}
                    >
                        <span className="sr-only">Go to last page</span>
                        <DoubleArrowRightIcon className="h-4 w-4" />
                    </Button>
                </div>
            </div>
        </div>
    )
}
