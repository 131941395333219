import { toStringAndSkipQuotationMarks } from '@/shared/utils/string-utils'
import { DataToExport } from '@/components/ui/table/actions/action-export-data/export-dialog'
import { Job } from '@/schemas/entities/job.schema'

export const fromJobToCSVLines = (jobs: Job[]): DataToExport => {
    const csvLines: string[][] = [
        [
            'job_title',
            'url',
            'posted_date',
            'job_country_code',
            'is_remote',
            'job_location',
            'job_description',
            'salary',
            'hiring_manager_full_name',
            'hiring_manager_first_name',
            'hiring_manager_role',
            'hiring_manager_linkedin_url',
            'company_name',
            'company_url',
            'company_linkedin_url',
            'company_industry',
            'company_employee_count',
            'company_revenue_usd',
            'company_seo_description',
            'company_description',
        ],
    ]

    jobs.forEach((job) => {
        csvLines.push([
            toStringAndSkipQuotationMarks(job.job_title),
            toStringAndSkipQuotationMarks(job.url),
            toStringAndSkipQuotationMarks(job.date_posted),
            toStringAndSkipQuotationMarks(job.country_code),
            job.remote ? 'true' : 'false',
            toStringAndSkipQuotationMarks(job.location),
            toStringAndSkipQuotationMarks(job.description),
            toStringAndSkipQuotationMarks(job.salary_string),
            toStringAndSkipQuotationMarks(job.hiring_team?.[0]?.full_name),
            toStringAndSkipQuotationMarks(job.hiring_team?.[0]?.first_name),
            toStringAndSkipQuotationMarks(job.hiring_team?.[0]?.role),
            toStringAndSkipQuotationMarks(job.hiring_team?.[0]?.linkedin_url),
            toStringAndSkipQuotationMarks(job.company),
            toStringAndSkipQuotationMarks(job.company_object?.url),
            toStringAndSkipQuotationMarks(job.company_object?.linkedin_url),
            toStringAndSkipQuotationMarks(job.company_object?.industry),
            toStringAndSkipQuotationMarks(job.company_object?.employee_count?.toString()),
            toStringAndSkipQuotationMarks(job.company_object?.annual_revenue_usd?.toString()),
            toStringAndSkipQuotationMarks(job.company_object?.seo_description),
            toStringAndSkipQuotationMarks(job.company_object?.long_description),
        ])
    })

    return { records: csvLines, columns_with_links: [1, 13, 14] }
}
