import { toStringAndSkipQuotationMarks } from '@/shared/utils/string-utils'
import { CompanyFromList } from '@/schemas/entities/companies-from-company-list'
import { DataToExport } from '@/components/ui/table/actions/action-export-data/export-dialog'

export const fromCompanyListToCSVLines = (companylist: CompanyFromList[]): DataToExport => {
    const csvLines: string[][] = [
        [
            'company_name',
            'company_url',
            'company_country',
            'company_country_code',
            'company_linkedin_url',
            'company_industry',
            'company_employee_count',
            'company_seo_description',
            'company_description',
        ],
    ]

    companylist.forEach((companyFromList) => {
        csvLines.push([
            toStringAndSkipQuotationMarks(companyFromList.company_object?.name),
            toStringAndSkipQuotationMarks(companyFromList.company_object?.url),
            toStringAndSkipQuotationMarks(companyFromList.company_object?.country),
            toStringAndSkipQuotationMarks(companyFromList.company_object?.country_code),
            toStringAndSkipQuotationMarks(companyFromList.company_object?.linkedin_url),
            toStringAndSkipQuotationMarks(companyFromList.company_object?.industry),
            toStringAndSkipQuotationMarks(companyFromList.company_object?.employee_count?.toString()),
            toStringAndSkipQuotationMarks(companyFromList.company_object?.seo_description?.toString()),
            toStringAndSkipQuotationMarks(companyFromList.company_object?.long_description?.toString()),
        ])
    })

    return {
        records: csvLines,
        columns_with_links: [],
    }
}
