import { DataToExport } from '@/components/ui/table/actions/action-export-data/export-dialog'
import { TechDetails } from '@/schemas/entities/tech-details.schema'
import { toStringAndSkipQuotationMarks } from '@/shared/utils/string-utils'

export const fromTechDetailsToCSVLines = (techDetails: TechDetails[]): DataToExport => {
    const csvLines: string[][] = [
        [
            'technology_name',
            'technology_category',
            'technology_jobs',
            'technology_first_date_found',
            'technology_last_date_found',
            'technology_rank_within_category',
            'technology_relative_occurrence_within_category',
            'technology_confidence',
            'technology_jobs_last_7_days',
            'technology_jobs_last_30_days',
            'technology_jobs_last_180_days',
        ],
    ]

    techDetails.forEach((techDetail) => {
        csvLines.push([
            toStringAndSkipQuotationMarks(techDetail.technology.name),
            toStringAndSkipQuotationMarks(techDetail.technology.category ?? ''),
            toStringAndSkipQuotationMarks(techDetail.jobs?.toString()),
            toStringAndSkipQuotationMarks(techDetail.first_date_found),
            toStringAndSkipQuotationMarks(techDetail.last_date_found),
            toStringAndSkipQuotationMarks(techDetail.rank_within_category?.toString()),
            toStringAndSkipQuotationMarks(techDetail.relative_occurrence_within_category?.toString()),
            toStringAndSkipQuotationMarks(techDetail.confidence),
            toStringAndSkipQuotationMarks(techDetail.jobs_last_7_days?.toString()),
            toStringAndSkipQuotationMarks(techDetail.jobs_last_30_days?.toString()),
            toStringAndSkipQuotationMarks(techDetail.jobs_last_180_days?.toString()),
        ])
    })

    return {
        records: csvLines,
        columns_with_links: [],
    }
}
