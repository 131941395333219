import { z } from 'zod'
import { baseSearchParamsSchema } from './base-search-params.schema'

export const techSearchParamsSchema = baseSearchParamsSchema.extend({
    company_domain_or: z.array(z.string()).optional(),
    company_domain_not: z.array(z.string()).optional(),
    only_yc_companies: z.boolean().optional().nullable(),
    min_employee_count: z.number().optional().nullable(),
    max_employee_count: z.number().optional().nullable(),
    min_funding_usd: z.number().optional().nullable(),
    max_funding_usd: z.number().optional().nullable(),
    min_revenue_usd: z.number().optional().nullable(),
    max_revenue_usd: z.number().optional().nullable(),
    max_rank: z.number().optional().nullable(),
    min_jobs: z.number().optional().nullable(),
    max_jobs: z.number().optional().nullable(),
    min_relative_occurrence: z.number().optional().nullable(),
    first_date_found_lte: z.string().optional().nullable(),
    first_date_found_gte: z.string().optional().nullable(),
    last_date_found_lte: z.string().optional().nullable(),
    last_date_found_gte: z.string().optional().nullable(),
    technology_slug_or: z.array(z.string()).optional(),
    company_technology_slug_or: z.array(z.string()).optional(),
    company_technology_slug_and: z.array(z.string()).optional(),
    company_technology_slug_not: z.array(z.string()).optional(),
    categories: z.array(z.string()).optional(),
    company_name_or: z.array(z.string()).optional(),
    company_name_not: z.array(z.string()).optional(),
    company_name_partial_match_or: z.array(z.string()).optional(),
    company_name_partial_match_not: z.array(z.string()).optional(),
    company_type: z.enum(['recruiting_agency', 'direct_employer', 'all']).optional(),
    job_titles: z.array(z.string()).optional(),
    keywords_or: z.array(z.string()).optional(),
    company_description_pattern_or: z.array(z.string()).optional(),
    company_description_pattern_not: z.array(z.string()).optional(),
    funding_stage_or: z.array(z.string()).optional(),
    company_list_id_or: z.array(z.number()).optional(),
    company_list_id_not: z.array(z.number()).optional(),
    job_country_codes_or: z.array(z.string()).optional(),
    company_country_code_or: z.array(z.string()).optional(),
    company_investors_or: z.array(z.string()).optional(),
    industry_id_or: z.array(z.number()).optional(),
    industry_id_not: z.array(z.number()).optional(),
    companies_urls: z.array(z.string()).optional(),
    job_country_code_or: z.array(z.string()).optional(),
    job_title_or: z.array(z.string()).optional(),
    confidence_or: z.array(z.string()).optional(),
})

export type TechSearchParams = z.infer<typeof techSearchParamsSchema>
