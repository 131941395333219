import { useEffect } from 'react'

function useWarnIfUnsavedChanges(unsavedChanges: boolean) {
    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (unsavedChanges) {
                // Cancel the event as stated by the standard.
                event.preventDefault()
                // Chrome requires returnValue to be set.
                event.returnValue = ''
            }
        }

        // Add event listener
        window.addEventListener('beforeunload', handleBeforeUnload)

        // Remove event listener on cleanup
        return () => window.removeEventListener('beforeunload', handleBeforeUnload)
    }, [unsavedChanges]) // Only re-run the effect if unsavedChanges changes
}

export default useWarnIfUnsavedChanges
