import { countries } from '@/data/countries'
import { funding_stages } from '@/data/founding-stages'
import { IFiltersProps, IOperator } from '@/shared/schemas/entity/filter.schema'
import {
    BackpackIcon,
    CalendarIcon,
    CaseUpperIcon,
    DollarSign,
    Factory,
    FrameIcon,
    Laptop,
    Link,
    LinkedinIcon,
    MapPin,
    UserIcon,
    Wallet,
} from 'lucide-react'

export const filters: IFiltersProps[] = [
    {
        id: 'posted_at',
        icon: CalendarIcon,
        title: 'Posted date',
        description: 'Date when the job was posted on a job board or company website',
        data_type: 'number',
        availableOperators: [IOperator.max_age_days, IOperator.between_dates],
        areOperatorsAccumulative: false,
        group: 'Job Posting',
        is_permanent: true,
    },
    {
        id: 'discovered_at',
        icon: CalendarIcon,
        title: 'Discovered date',
        description: 'Date when the job was discovered by TheirStack',
        data_type: 'number',
        availableOperators: [IOperator.max_age_days, IOperator.min_age_days, IOperator.between_dates],
        areOperatorsAccumulative: true,
        group: 'Job Posting',
    },
    {
        id: 'job_country_code',
        icon: MapPin,
        title: 'Job Country',
        description: 'Country where the job is located',
        data_type: 'options[]',
        availableOperators: [IOperator.contains_any, IOperator.not_contains_any],
        areOperatorsAccumulative: true,
        availableValues: countries,
        group: 'Job Posting',
        is_permanent: true,
    },
    {
        id: 'job_title',
        icon: CaseUpperIcon,
        title: 'Job Title',
        description: 'Job title as it appears on the job board or company website',
        data_type: 'string[]',
        availableOperators: [
            IOperator.contains_any,
            IOperator.not_contains_any,
            IOperator.contains_any_regex,
            IOperator.not_contains_any_regex,
            IOperator.contains_all_regex,
        ],
        areOperatorsAccumulative: true,
        group: 'Job Posting',
        is_permanent: true,
    },
    {
        id: 'job_description',
        icon: CaseUpperIcon,
        title: 'Job Description',
        description: 'Job description as it appears on the job board or company website',
        data_type: 'string[]',
        availableOperators: [IOperator.contains_any_regex, IOperator.not_contains_any_regex],
        areOperatorsAccumulative: true,
        group: 'Job Posting',
    },
    {
        id: 'job_location',
        icon: MapPin,
        title: 'Job Location',
        description: 'City or region where the job is located',
        data_type: 'string[]',
        availableOperators: [IOperator.contains_any_regex, IOperator.not_contains_any_regex],
        areOperatorsAccumulative: true,
        group: 'Job Posting',
    },
    {
        id: 'company_name',
        icon: BackpackIcon,
        title: 'Company Name',
        description: 'Name of the company that posted the job',
        data_type: 'string[]',
        availableOperators: [
            IOperator.contains_any_case_insensitive,
            IOperator.contains_any,
            IOperator.not_contains_any,
            IOperator.contains_any_partial_match,
            IOperator.not_contains_any_partial_match,
        ],
        areOperatorsAccumulative: true,
        group: 'Company',
    },
    {
        id: 'company_description',
        icon: BackpackIcon,
        title: 'Company Description',
        description: 'Description of the company that posted the job',
        data_type: 'string[]',
        availableOperators: [IOperator.contains_any_regex, IOperator.not_contains_any_regex],
        areOperatorsAccumulative: true,
        group: 'Company',
    },
    {
        id: 'employee_count',
        icon: FrameIcon,
        title: 'Employees',
        description: 'Number of employees of the company that posted the job',
        data_type: 'number[]',
        availableValues: [
            { id: '10', label: '10' },
            { id: '50', label: '50' },
            { id: '200', label: '200' },
            { id: '500', label: '500' },
            { id: '1000', label: '1,000' },
            { id: '5000', label: '5,000' },
            { id: '10000', label: '10,000' },
        ],
        availableOperators: [IOperator.greater, IOperator.less, IOperator.greater_or_null, IOperator.less_or_null],
        areOperatorsAccumulative: true,
        group: 'Company',
    },
    {
        id: 'company_country_code',
        icon: MapPin,
        title: 'Company HQ Country',
        description: 'Country where the company is headquartered',
        data_type: 'options[]',
        availableOperators: [IOperator.contains_any, IOperator.not_contains_any],
        areOperatorsAccumulative: true,
        availableValues: countries,
        group: 'Company',
    },
    {
        id: 'revenue_usd',
        icon: DollarSign,
        title: 'Company Revenue (USD)',
        description: 'Annual revenue of the company that posted the job',
        data_type: 'number[]',
        availableValues: [
            { id: '1000000', label: '$1M' },
            { id: '5000000', label: '$5M' },
            { id: '10000000', label: '$10M' },
            { id: '50000000', label: '$50M' },
            { id: '100000000', label: '$100M' },
            { id: '500000000', label: '$500M' },
            { id: '1000000000', label: '$1B' },
            { id: '2000000000', label: '$2B' },
            { id: '5000000000', label: '$5B' },
            { id: '10000000000', label: '$10B' },
            { id: '20000000000', label: '$20B' },
            { id: '50000000000', label: '$50B' },
        ],
        availableOperators: [IOperator.greater, IOperator.less],
        areOperatorsAccumulative: true,
        group: 'Company',
    },
    {
        id: 'funding_usd',
        icon: DollarSign,
        title: 'Company Funding',
        description: 'Total funding raised by the company that posted the job',
        data_type: 'number[]',
        availableValues: [
            { id: '100000', label: '$100k' },
            { id: '500000', label: '$500k' },
            { id: '1000000', label: '$1M' },
            { id: '5000000', label: '$5M' },
            { id: '10000000', label: '$10M' },
            { id: '50000000', label: '$50M' },
            { id: '100000000', label: '$100M' },
            { id: '500000000', label: '$500M' },
            { id: '1000000000', label: '$1B' },
        ],
        availableOperators: [IOperator.greater, IOperator.less],
        areOperatorsAccumulative: true,
        group: 'Company',
    },
    {
        id: 'company_type',
        icon: BackpackIcon,
        title: 'Company Type',
        description: 'Type of company that posted the job: Recruiting Agency or Direct Employer',
        data_type: 'options',
        availableOperators: [IOperator.is],
        availableValues: [
            { id: 'recruiting_agency', label: 'Recruiting Agency' },
            { id: 'direct_employer', label: 'Direct employer' },
        ],
        areOperatorsAccumulative: true,
        group: 'Company',
    },
    {
        id: 'funding_stage',
        icon: DollarSign,
        title: 'Company Funding Stages',
        description: 'Stage of funding the company is in like Seed, Series A, Series B, etc.',
        data_type: 'options[]',
        availableOperators: [IOperator.contains_any],
        areOperatorsAccumulative: true,
        availableValues: funding_stages,
        group: 'Company',
    },
    {
        id: 'company_domain',
        icon: Link,
        title: 'Website Domain',
        description: 'Company website domain like apple.com, google.com, etc.',
        data_type: 'string[]',
        availableOperators: [IOperator.contains_any, IOperator.not_contains_any],
        areOperatorsAccumulative: true,
        group: 'Company',
    },
    {
        id: 'salary_usd',
        icon: DollarSign,
        title: 'Annual Salary (usd)',
        description: 'Annual salary of the job posting',
        data_type: 'number',
        availableOperators: [IOperator.greater, IOperator.less],
        areOperatorsAccumulative: true,
        group: 'Job Posting',
    },
    {
        id: 'remote',
        icon: Laptop,
        title: 'Remote',
        description: 'Is the job remote?',
        data_type: 'boolean',
        availableOperators: [IOperator.is],
        areOperatorsAccumulative: true,
        group: 'Job Posting',
    },
    {
        id: 'job_technology_slug',
        icon: Laptop,
        title: 'Job Technology',
        description: 'Technology mentioned in the job description',
        data_type: 'technologies[]',
        availableOperators: [IOperator.contains_any, IOperator.contains_all, IOperator.not_contains_any],
        areOperatorsAccumulative: true,
        group: 'Job Posting',
    },
    {
        id: 'company_technology_slug',
        icon: Laptop,
        title: 'Company Technology',
        description: 'Technology used by the company that posted the job',
        data_type: 'technologies[]',
        availableOperators: [IOperator.contains_any, IOperator.contains_all, IOperator.not_contains_any],
        areOperatorsAccumulative: true,
        group: 'Company',
    },
    {
        id: 'industry_id',
        icon: Factory,
        title: 'Company Industry',
        description: 'Industry of the company that posted the job',
        data_type: 'industry[]',
        availableOperators: [IOperator.contains_any, IOperator.not_contains_any],
        areOperatorsAccumulative: true,
        group: 'Company',
    },
    {
        id: 'company_list_id',
        icon: BackpackIcon,
        title: 'Company List',
        description: 'Company is part of any of the selected company lists',
        data_type: 'company-list[]',
        availableOperators: [IOperator.contains_any, IOperator.not_contains_any],
        areOperatorsAccumulative: true,
        group: 'Company',
    },
    {
        id: 'company_linkedin_url',
        icon: LinkedinIcon,
        title: 'Linkedin URL',
        description: 'Company Linkedin URL',
        data_type: 'string[]',
        availableOperators: [IOperator.contains_any],
        areOperatorsAccumulative: true,
        group: 'Company',
    },
    {
        id: 'company_linkedin_url_exists',
        icon: LinkedinIcon,
        title: 'Linkedin URL exists',
        description: 'Company Linkedin URL',
        data_type: 'boolean',
        availableOperators: [IOperator.is],
        areOperatorsAccumulative: true,
        group: 'Company',
    },
    {
        id: 'revealed_company_data',
        icon: Wallet,
        title: 'Is Company Revealed ?',
        description:
            'Has the company data been revealed in the current billing period? Not credits will be consumed if true',
        data_type: 'boolean',
        availableOperators: [IOperator.is],
        areOperatorsAccumulative: true,
        group: 'Company',
    },
    {
        id: 'company_location',
        icon: MapPin,
        title: 'Company City',
        description: 'City where the company is headquartered',
        data_type: 'string[]',
        availableOperators: [IOperator.contains_any_regex],
        areOperatorsAccumulative: true,
        group: 'Company',
    },
    {
        id: 'only_jobs_with_hiring_managers',
        icon: UserIcon,
        title: 'Has Hiring Manager',
        description: 'Company has a public hiring manager profile on Linkedin',
        data_type: 'boolean',
        availableOperators: [IOperator.is],
        areOperatorsAccumulative: true,
        group: 'Job Posting',
    },
    {
        id: 'reports_to_exists',
        icon: UserIcon,
        title: 'Includes Reports To Role',
        description: 'The jobs mentions the roles of the person that would be reported to',
        data_type: 'boolean',
        availableOperators: [IOperator.is],
        areOperatorsAccumulative: true,
        group: 'Job Posting',
    },
    {
        id: 'final_url_exists',
        icon: UserIcon,
        title: 'ATS URL exists',
        description: 'The ATS URL of the job exists',
        data_type: 'boolean',
        availableOperators: [IOperator.is],
        areOperatorsAccumulative: true,
        group: 'Job Posting',
    },
    {
        id: 'scraper_name_pattern',
        icon: BackpackIcon,
        title: 'Scraping Source',
        description: 'Name of the job board or ATS where the job was discovered',
        data_type: 'options[]',
        availableOperators: [IOperator.contains_any],
        areOperatorsAccumulative: true,
        availableValues: [
            { id: 'Indeed', label: 'Indeed' },
            { id: 'LinkedIn', label: 'LinkedIn' },
            { id: 'Infojobs', label: 'Infojobs' },
            { id: 'workable', label: 'Workable' },
            { id: 'otta', label: 'Otta' },
            { id: 'Startup.jobs', label: 'Startup.jobs' },
            { id: 'Tecnoempleo', label: 'Tecnoempleo' },
            { id: 'YC', label: 'YC' },
            { id: 'Jooble', label: 'Jooble' },
            { id: 'Manfred', label: 'Manfred' },
            { id: 'Remotive', label: 'Remotive' },
            { id: 'PMFarma', label: 'PMFarma' },
            { id: 'Angellist', label: 'Angellist' },
            { id: 'Circular', label: 'Circular' },
        ],
        group: 'Job Posting',
        deprecated: true,
    },
]
