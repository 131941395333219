import { Table } from '@tanstack/react-table'
import { useEffect, useState } from 'react'

export function useCalculateOperationCreditsCost<T>({
    table,
    limit,
    enabled = true,
}: {
    table: Table<T>
    limit: number
    enabled?: boolean
}) {
    const [operationCreditsCost, setOperationCreditsCost] = useState<number | undefined>(undefined)
    const [isCalculatingCreditsCost, setIsCalculatingCreditsCost] = useState(false)

    useEffect(() => {
        if (!enabled) return
        if (limit === 0) {
            setOperationCreditsCost(0)
            return
        }
        setIsCalculatingCreditsCost(true)
        setOperationCreditsCost(undefined)
        table.options?.meta?.calculateCredits(limit).then((credits: number) => {
            setOperationCreditsCost(credits)
            setIsCalculatingCreditsCost(false)
        })
    }, [limit, enabled])

    return { operationCreditsCost, isCalculatingCreditsCost }
}
