import { isEmpty, truncateString } from '@/shared/utils/string-utils'
import { utils, writeFile } from 'xlsx'

export const EXCEL_LIB_MAX_CHAR_PER_LINE = 32766

export function download_excel(data: string[][], filename: string, columns_with_links: number[]) {
    const date = new Date().toISOString().slice(0, 10)
    const wb = utils.book_new()
    const ws = utils.aoa_to_sheet(truncateLongValues(data))
    utils.book_append_sheet(wb, ws, truncateString(filename, 31))
    ws['!cols'] = Array(data[0].length).fill({ wpx: 150, hpt: 30 })
    formatLinkColumns(data, columns_with_links, ws)
    writeFile(wb, `${filename}_${date}.xlsx`)
}

function formatLinkColumns(data: string[][], columns_with_links: number[], ws: any) {
    for (let row_index = 1; row_index < data.length; row_index++) {
        for (let column_index = 0; column_index < columns_with_links.length; column_index++) {
            const column_number = columns_with_links[column_index]
            const column_letter = String.fromCharCode(65 + column_number)

            const target = data[row_index][column_number]
            const formated_target =
                target?.includes('http://') || target?.includes('https://') || isEmpty(target)
                    ? target
                    : `https://${target}`
            ws[`${column_letter}${row_index + 1}`].l = {
                Target: formated_target,
                Tooltip: data[row_index][column_number],
            }
        }
    }
}

export const truncateLongValues = (lines: string[][]): string[][] => {
    lines.forEach((line) => {
        line.forEach((value, index) => {
            if (value.length > EXCEL_LIB_MAX_CHAR_PER_LINE) {
                line[index] = value.slice(0, EXCEL_LIB_MAX_CHAR_PER_LINE)
            }
        })
    })
    return lines
}
