import { Command, CommandEmpty, CommandGroup, CommandItem, CommandList } from '@/shared/ui/base/command'
import { MultiSelectValues } from '../../../shared/schemas/entity/filter.schema'
import { Check } from 'lucide-react'
import { formatNumberWithComma } from '@/shared/utils/number-utils'

export function SearchFilterValuePreviewSelectNumber({
    value,
    availableValues,
}: {
    value: number
    availableValues: MultiSelectValues
}) {
    const label = availableValues.find((item) => item.id === value.toString())?.label
    return <p className="text-sm">{label ? label : formatNumberWithComma(value)}</p>
}

export function SearchFilterFormSelectNumber({
    value,
    onChangeValue,
    availableValues,
}: {
    onChangeValue: (value: number) => void
    availableValues: MultiSelectValues
    value: string
}) {
    const onChangeCondition = (newValue: string) => {
        if (newValue == value) return
        onChangeValue(+newValue)
    }
    return (
        <Command>
            <CommandList>
                <CommandEmpty>No results found.</CommandEmpty>
                <CommandGroup>
                    {availableValues.map((item) => (
                        <CommandItem
                            key={item.id}
                            onSelect={() => onChangeCondition(item.id)}
                            disabled={value == item.id}
                        >
                            {value == item.id && <Check className="h-4 w-4 mr-2" />}
                            {value != item.id && <div className="h-4 w-4 mr-2" />}
                            <span>{item.label}</span>
                        </CommandItem>
                    ))}
                </CommandGroup>
            </CommandList>
        </Command>
    )
}
