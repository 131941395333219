import * as React from 'react'
import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from 'recharts'

import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from '@/components/ui/base/chart'
import { RequestCount } from '@/schemas/entities/request-count.schema'
import { Card, CardContent, CardHeader } from '@/shared/ui/base/card'
import { Spinner } from '@/shared/ui/base/spinner'
import formatNumberToNDigits, { formatNumberWithComma } from '@/shared/utils/number-utils'
import { format } from 'date-fns'

export const description = 'An interactive bar chart'

const chartConfig = {
    requests: {
        label: 'Requests',
        color: 'hsl(var(--chart-2))',
    },
} satisfies ChartConfig

export function APIRequestCountChart({ data }: { data: RequestCount[] | undefined }) {
    const chart_data = data?.map((item) => ({
        date: format(new Date(item.period_start), 'yyyy-MM-dd'),
        requests: item.number_of_requests,
    }))
    const [activeChart, setActiveChart] = React.useState<keyof typeof chartConfig | null>(null)

    const total = React.useMemo(
        () => ({
            requests: chart_data?.reduce((acc, curr) => acc + curr.requests, 0),
        }),
        [chart_data]
    )

    return (
        <>
            {data === null && (
                <div className="flex justify-center">
                    <Spinner size="default" className="m-1" />
                </div>
            )}
            {data !== null && (
                <Card>
                    <CardHeader className="flex flex-col items-stretch justify-end space-y-0 border-b p-0 sm:flex-row">
                        <div className="flex">
                            {['requests'].map((key) => {
                                const chart = key as keyof typeof chartConfig
                                return (
                                    <button
                                        key={chart}
                                        data-active={activeChart === chart}
                                        className="relative z-30 flex flex-1 flex-col justify-center gap-1 border-t px-6 py-4 text-left even:border-l data-[active=true]:bg-muted/50 sm:border-l sm:border-t-0 sm:px-8 sm:py-6"
                                        onClick={() => setActiveChart((prev) => (prev === chart ? null : chart))}
                                    >
                                        <span className="text-xs text-muted-foreground">
                                            {chartConfig[chart].label}
                                        </span>
                                        <span className="text-lg font-bold leading-none sm:text-3xl">
                                            {formatNumberWithComma(total[key as keyof typeof total])}
                                        </span>
                                    </button>
                                )
                            })}
                        </div>
                    </CardHeader>
                    <CardContent className="px-2 sm:p-6">
                        <ChartContainer config={chartConfig} className="aspect-auto h-[250px] w-full">
                            <BarChart
                                accessibilityLayer
                                data={chart_data}
                                margin={{
                                    left: 12,
                                    right: 12,
                                }}
                            >
                                <CartesianGrid vertical={false} />
                                <XAxis
                                    dataKey="date"
                                    tickLine={false}
                                    axisLine={false}
                                    tickMargin={8}
                                    minTickGap={32}
                                    tickFormatter={(value) => {
                                        const date = new Date(value)
                                        return date.toLocaleDateString('en-US', {
                                            month: 'short',
                                            day: 'numeric',
                                        })
                                    }}
                                />
                                <YAxis
                                    tickLine={false}
                                    axisLine={false}
                                    tickFormatter={(value) => formatNumberToNDigits(value, 2)}
                                    tickMargin={8}
                                />

                                <ChartTooltip
                                    content={
                                        <ChartTooltipContent
                                            className="w-[150px]"
                                            nameKey="views"
                                            labelFormatter={(value) => {
                                                return new Date(value).toLocaleDateString('en-US', {
                                                    month: 'short',
                                                    day: 'numeric',
                                                    year: 'numeric',
                                                })
                                            }}
                                        />
                                    }
                                />
                                {activeChart === null ? (
                                    ['requests'].map((key) => {
                                        const chart = key as keyof typeof chartConfig
                                        return (
                                            <Bar
                                                key={chart}
                                                stackId="a"
                                                dataKey={chart}
                                                fill={`var(--color-${chart})`}
                                            />
                                        )
                                    })
                                ) : (
                                    <Bar dataKey={activeChart} stackId="a" fill={`var(--color-${activeChart})`} />
                                )}
                            </BarChart>
                        </ChartContainer>
                    </CardContent>
                </Card>
            )}
        </>
    )
}
