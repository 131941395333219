import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/ui/base/form'
import { Button } from '@/shared/ui/base/button'

import { Checkbox } from '@/shared/ui/base/checkbox'
import { FormLabel } from '@/components/ui/base/form'
import { Search } from 'lucide-react'
import { CompanyList } from '@/schemas/entities/company-list.schema'
import { getAllCompanyLists } from '@/services/company_list.service'
import { formatNumberWithComma } from '@/shared/utils/number-utils'
import { areObjectsEqual } from '@/lib/search-params-utils'

const FormSchema = z.object({
    items: z.array(z.number()),
})

export function SearchFilterValuePreviewCompanyList({ value }: { value: number[] }) {
    const [list_names, setListNames] = useState<string[]>([])
    const length = value ? value.length : 0

    useEffect(() => {
        getAllCompanyLists().then((lists) => {
            const names = lists.filter((list) => value.includes(list.id)).map((list) => list.name)
            setListNames(names)
        })
    }, [])

    return (
        <p className="text-sm">{`${list_names ? list_names.slice(0, 2).join(', ') : ''}${length > 2 ? `, + ${length - 2}` : ''}`}</p>
    )
}

export function SearchFilterFormCompanyList({
    value,
    onChangeValue,
    onInteractOutside,
    closePopover,
}: {
    value: number[]
    onChangeValue: (_value: number[]) => void
    onInteractOutside: boolean
    closePopover: () => void
}) {
    const [searchQuery, setSearchQuery] = useState('')
    const [companylists, setCompanyLists] = useState<CompanyList[]>([])
    const form = useForm<z.infer<typeof FormSchema>>({
        resolver: zodResolver(FormSchema),
        defaultValues: {
            items: value || [],
        },
    })
    useEffect(() => {
        if (onInteractOutside === true) {
            onSubmit(form.getValues())
        }
    }, [onInteractOutside])

    useEffect(() => {
        getAllCompanyLists().then((data) => setCompanyLists(data.filter((item) => item.name != 'Companies seen')))
    }, [])

    function onSubmit(data: z.infer<typeof FormSchema>) {
        if (areObjectsEqual(data.items, value)) {
            closePopover()
            return
        }
        onChangeValue(data.items)
    }

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="grid max-w-sm items-center gap-y-4">
                <div className="flex items-center border rounded px-3" cmdk-input-wrapper="">
                    <Search className="mr-2 h-4 w-4 shrink-0 opacity-50" />
                    <input
                        type="text"
                        value={searchQuery}
                        autoFocus={true}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        placeholder="Search..."
                        className="flex h-9 w-full rounded-md bg-transparent py-3 text-sm outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50"
                    />
                </div>
                <FormField
                    control={form.control}
                    name="items"
                    render={() => (
                        <FormItem className="max-h-[200px] overflow-scroll">
                            <FormMessage />
                            {companylists
                                .filter((item) => item.name.toLowerCase().includes(searchQuery.toLowerCase()))
                                .map((item) => (
                                    <FormField
                                        key={item.name}
                                        control={form.control}
                                        name="items"
                                        render={({ field }) => {
                                            return (
                                                <FormItem
                                                    key={item.name}
                                                    className="flex flex-row items-start space-x-3 space-y-0"
                                                >
                                                    <FormControl>
                                                        <Checkbox
                                                            id={item.id.toString()}
                                                            checked={field.value?.includes(item.id)}
                                                            onCheckedChange={(checked) => {
                                                                return checked
                                                                    ? field.onChange([...(field.value || []), item.id])
                                                                    : field.onChange(
                                                                          field.value?.filter(
                                                                              (value) => value !== item.id
                                                                          )
                                                                      )
                                                            }}
                                                        />
                                                    </FormControl>
                                                    <FormLabel
                                                        htmlFor={item.id.toString()}
                                                        className="text-sm font-normal cursor-pointer hover:underline"
                                                    >
                                                        {item.name} ({formatNumberWithComma(item.companies_count)})
                                                    </FormLabel>
                                                </FormItem>
                                            )
                                        }}
                                    />
                                ))}
                        </FormItem>
                    )}
                />
                <Button type="submit" size="sm">
                    Save
                </Button>
            </form>
        </Form>
    )
}
