import * as Sentry from '@sentry/react'

export function initSentry() {
    if (Sentry.isInitialized()) return
    Sentry.init({
        dsn: 'https://2d695b933852b956e62cdb851c19a46b@o304275.ingest.sentry.io/4506586556727296',
        tracePropagationTargets: ['app.theirstack.com'],
        integrations: [
            new Sentry.BrowserTracing(),
            new Sentry.Replay({
                maskAllText: false,
                blockAllMedia: false,
                networkDetailAllowUrls: [
                    /https:\/\/api\.theirstack\.com\/.*/,
                    /https:\/\/noicejobs.herokuapp\.com\/.*/,
                ],
            }),
        ],
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 0.1,
    })
}
