import { DataToExport } from '@/components/ui/table/actions/action-export-data/export-dialog'
import { EXCEL_LIB_MAX_CHAR_PER_LINE } from '@/lib/excel-utils'
import { FullCompany } from '@/schemas/entities/full-company.schema'
import { stringify, toStringAndSkipQuotationMarks } from '@/shared/utils/string-utils'

export const fromCompanyToCSVLines = (companies: FullCompany[]): DataToExport => {
    const tecnhology_found_slugs = [
        ...new Set(
            companies.flatMap((company) => company.technologies_found?.map((tech) => tech.technology.slug) || [])
        ),
    ]

    const header = [
        'company_name',
        'company_url',
        'company_linkedin_url',
        'company_country',
        'company_country_code',
        'company_industry',
        'company_employee_count',
        'company_founding_year',
        'technologies_count',
        'technologies',
        'jobs_count',
        'jobs_titles',
        'annual_revenue_usd',
        'company_seo_description',
        'company_description',
    ]

    tecnhology_found_slugs.forEach((slug: string) => {
        header.push(`${slug}_confidence`)
        header.push(`${slug}_n_jobs`)
    })
    const csvLines: string[][] = [header]
    companies.forEach((company) => {
        const line = [
            stringify(company.name),
            stringify(company.url),
            stringify(company.linkedin_url),
            stringify(company.country),
            stringify(company.country_code),
            stringify(company.industry),
            stringify(company.employee_count?.toString()),
            stringify(company.founded_year?.toString()),
            stringify(company.technology_slugs?.length.toString()),
            stringify(company.technologies_found?.map((tech) => tech.technology.name).join(', ')),
            stringify(company.num_jobs?.toString()),
            stringify(company.jobs_found?.map((job) => job.job_title).join('", "')),
            stringify(company.annual_revenue_usd?.toString()),
            toStringAndSkipQuotationMarks(company?.seo_description),
            toStringAndSkipQuotationMarks(company?.long_description),
        ]
        tecnhology_found_slugs.forEach((slug: string) => {
            const tech = company.technologies_found?.find((tech) => tech.technology.slug === slug)
            line.push(tech?.confidence?.toString() || '')
            line.push(tech?.jobs?.toString() || '')
        })
        csvLines.push(line)
    })

    csvLines.forEach((line) => {
        line.forEach((value, index) => {
            if (value.length > EXCEL_LIB_MAX_CHAR_PER_LINE) {
                line[index] = value.slice(0, EXCEL_LIB_MAX_CHAR_PER_LINE)
            }
        })
    })

    return { records: csvLines, columns_with_links: [1, 2] }
}
