import { ColumnDef, Table } from '@tanstack/react-table'

import { DataTablePagination } from '@/components/ui/table/data-table-pagination'
import { DataTable } from '@/components/ui/table/data-table'
import { DataTableToolbar } from './data-table-toolbar'
import { CompanyFromListSearchParams } from '@/schemas/request/companies-from-list-search-params.schema'
import { CompanyFromList } from '@/schemas/entities/companies-from-company-list'

interface DataTableProps {
    table: Table<CompanyFromList>
    columns: ColumnDef<CompanyFromList>[]
    isDataFetching: boolean
    onChangeFilters: (_params: CompanyFromListSearchParams) => void
    searchedSP: CompanyFromListSearchParams
    totalResults: number
    filter_by?: string
}

export function DataTableWithPagination({
    table,
    columns,
    isDataFetching,
    searchedSP,
    onChangeFilters,
    totalResults,
    filter_by,
}: DataTableProps) {
    return (
        <>
            <DataTableToolbar
                table={table}
                filter_by={filter_by}
                isDataFetching={isDataFetching}
                onChangeFilters={onChangeFilters}
                searchedSP={searchedSP}
            />
            <DataTable table={table} columns={columns} isDataFetching={isDataFetching} />
            <DataTablePagination table={table} totalResults={totalResults} />
        </>
    )
}
