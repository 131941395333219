import { cn } from '@/lib/utils'
import { buttonVariants } from '@/shared/ui/base/button'
import { NavLink } from 'react-router-dom'

interface SidebarNavProps extends React.HTMLAttributes<HTMLElement> {
    items: {
        href: string
        title: string
    }[]
}

export function SidebarNav({ className, items, ...props }: SidebarNavProps) {
    return (
        <nav className={cn('flex gap-2 lg:flex-col lg:space-x-0 lg:space-y-1 w-full', className)} {...props}>
            {items.map((item) => (
                <NavLink
                    key={item.href}
                    to={item.href}
                    className={({ isActive }) =>
                        cn(
                            buttonVariants({ variant: 'ghost', size: 'sm' }),
                            isActive
                                ? 'bg-muted hover:bg-muted '
                                : 'hover:bg-transparent hover:underline font-normal text-gray-600',
                            'justify-start'
                        )
                    }
                >
                    {item.title}
                </NavLink>
            ))}
        </nav>
    )
}
