import { useEffect } from 'react'

export default function TallyForm({ src, onFormSubmitted }: { src: string; onFormSubmitted: () => void }) {
    // The code below will load the embed
    useEffect(() => {
        const widgetScriptSrc = 'https://tally.so/widgets/embed.js'

        const load = () => {
            // Load Tally embeds
            // @ts-expect-error Tally is not defined
            if (typeof Tally !== 'undefined') {
                // @ts-expect-error Tally is not defined
                Tally.loadEmbeds()
                return
            }

            // Fallback if window.Tally is not available
            document.querySelectorAll('iframe[data-tally-src]:not([src])').forEach((iframeEl) => {
                // @ts-expect-error Tally is not defined
                iframeEl.src = iframeEl.dataset.tallySrc
            })
        }

        // If Tally is already loaded, load the embeds
        // @ts-expect-error Tally is not defined
        if (typeof Tally !== 'undefined') {
            load()
            return
        }

        // If the Tally widget script is not loaded yet, load it
        if (document.querySelector(`script[src="${widgetScriptSrc}"]`) === null) {
            const script = document.createElement('script')
            script.src = widgetScriptSrc
            script.onload = load
            script.onerror = load
            document.body.appendChild(script)
            return
        }
    }, [])

    useEffect(() => {
        const handleMessageEvent = (event: MessageEvent) => {
            if (String(event.data)?.includes('Tally.FormSubmitted')) {
                onFormSubmitted()
            }
        }
        window.addEventListener('message', handleMessageEvent)
        // Cleanup on component unmount
        return () => {
            window.removeEventListener('message', handleMessageEvent)
        }
    }, []) // Empty dependency array means this effect runs once on mount

    return (
        <div>
            <iframe
                loading="lazy"
                data-tally-src={src}
                width="100%"
                className="w-full aspect-video"
                title="Contact form"
            />
        </div>
    )
}
