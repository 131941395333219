import { Maximize2 } from 'lucide-react'

export function OpenSidePeekLabel({ onClick }: { onClick: () => void }) {
    return (
        <div className="absolute right-0 top-1/2 -translate-y-1/2">
            <button
                onClick={onClick}
                className="hidden group-hover:flex flex-row items-center gap-1 bg-white border-gray-400  rounded-md px-1 py-0.5 cursor-pointer shadow-md"
                style={{ borderWidth: '1px' }}
            >
                <p className="text-gray-500 text-xs font-medium">OPEN</p>
                <Maximize2 className="text-gray-500 w-3 h-3" strokeWidth={2.5} />
            </button>
        </div>
    )
}
