import { Button } from '../../../../shared/ui/base/button'
import { HelpCircle, LibraryBig, MessageCircle, Slack, VideoIcon } from 'lucide-react'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '../../base/dropdown-menu'
import { Dialog, DialogContent } from '@/shared/ui/base/dialog'
import TallyForm from '@/components/ui/tally.form'
import { DialogTitle } from '@/shared/ui/base/dialog'
import * as VisuallyHidden from '@radix-ui/react-visually-hidden'
import { useState } from 'react'
import { getMyProfile } from '@/services/user.service'
import { THERE_MINUTE_IN_MS } from '@/shared/utils/date-utils'
import { UserWithTeam } from '@/schemas/entities/user'
import { useQuery } from '@tanstack/react-query'

export default function HelpButton() {
    const [showContactUsDialog, setShowContactUsDialog] = useState(false)
    const { data: me = null } = useQuery<UserWithTeam>({
        queryKey: ['me'],
        queryFn: getMyProfile,
        staleTime: THERE_MINUTE_IN_MS,
    })

    return (
        <>
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <Button variant="outline">
                        <HelpCircle className="w-5 h-5" />
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-60" align="end" forceMount>
                    <DropdownMenuGroup>
                        <DropdownMenuItem className="cursor-pointer" onClick={() => setShowContactUsDialog(true)}>
                            <MessageCircle className="w-4 h-4 mr-1" />
                            Contact support
                        </DropdownMenuItem>
                        <a href="https://theirstack.com/en/docs/introduction" target="_blank">
                            <DropdownMenuItem className="cursor-pointer">
                                <LibraryBig className="w-4 h-4 mr-1" />
                                Docs and Guides
                            </DropdownMenuItem>
                        </a>
                        <a
                            href="https://join.slack.com/t/theirstack-community/shared_invite/zt-29lbkbrap-uwHDo9aOryYiRhPjDnQasg"
                            target="_blank"
                        >
                            <DropdownMenuItem className="cursor-pointer">
                                <Slack className="w-4 h-4 mr-1" />
                                Join our community in Slack
                            </DropdownMenuItem>
                        </a>
                        <a href="https://zcal.co/christianpalou/30min" target="_blank">
                            <DropdownMenuItem className="cursor-pointer">
                                <VideoIcon className="w-4 h-4 mr-1" /> Book a call with us
                            </DropdownMenuItem>
                        </a>
                    </DropdownMenuGroup>
                </DropdownMenuContent>
            </DropdownMenu>
            {me && showContactUsDialog && (
                <Dialog open={true} onOpenChange={setShowContactUsDialog}>
                    <DialogContent
                        className="overflow-scroll max-h-full maw-w-full sm:max-w-[900px]"
                        aria-describedby={undefined}
                    >
                        <VisuallyHidden.Root asChild>
                            <DialogTitle>Cancel subscription</DialogTitle>
                        </VisuallyHidden.Root>
                        <TallyForm
                            src={`https://tally.so/embed/3xyAYE?hideTitle=1&transparentBackground=1&dynamicHeight=1&email=${encodeURIComponent(me.email)}`}
                            onFormSubmitted={() => setShowContactUsDialog(false)}
                        />
                    </DialogContent>
                </Dialog>
            )}
        </>
    )
}
