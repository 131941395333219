import { Alert, AlertDescription, AlertTitle } from '@/components/ui/base/alert'
import UpgradeButton from '@/components/ui/layout/components/upgrade_button'
import { formatNumberWithComma } from '@/shared/utils/number-utils'

export function NotEnoughCredits({
    credits_left_current_period,
    operation_credits_cost,
}: {
    credits_left_current_period: number
    operation_credits_cost: number
}) {
    return (
        <Alert variant="warning">
            <AlertTitle>Insufficient company credits</AlertTitle>
            <AlertDescription>
                <span>
                    You have {formatNumberWithComma(credits_left_current_period)} company credits but this action
                    requires {formatNumberWithComma(operation_credits_cost)}. Please consider one of the following
                    options:
                </span>
                <div className="flex flex-col md:flex-row items-start justify-start gap-4">
                    <ul className="list-disc ml-6 mt-2">
                        <li>Purchase additional company credits</li>
                        <li>Select a smaller number of records</li>
                        <li>
                            Add filter "Is Company Revealed" = "Yes" to see only companies you’ve already consumed
                            company credits for
                        </li>
                    </ul>
                    <UpgradeButton variant="default" size="sm" />
                </div>
            </AlertDescription>
        </Alert>
    )
}
