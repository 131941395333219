import { z } from 'zod'
import { technologySchema } from './technology.schema'

export const techDetailsSchema = z.object({
    technology: technologySchema,
    jobs: z.number().optional(),
    first_date_found: z.string(),
    last_date_found: z.string(),
    rank_within_category: z.number().nullable(),
    relative_occurrence_within_category: z.number().nullable(),
    confidence: z.enum(['low', 'medium', 'high']),
    theirstack_score: z.number().nullable(),
    jobs_last_7_days: z.number(),
    jobs_last_30_days: z.number(),
    jobs_last_180_days: z.number(),
    company_name: z.string(),
})

export type TechDetails = z.infer<typeof techDetailsSchema>
