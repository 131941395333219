import { Dialog, DialogContent, DialogDescription, DialogTitle } from '@/shared/ui/base/dialog'
import { forwardRef, useImperativeHandle, useState } from 'react'
import UpgradeButton from '../layout/components/upgrade_button'
import { OpenDialogHandle } from './dialog-handle-interface'

export interface OutOfCreditsDialogProps {}

const OutOfCreditsDialog = forwardRef<OpenDialogHandle, OutOfCreditsDialogProps>((_props, ref) => {
    const [show, setShow] = useState(false)

    useImperativeHandle(ref, () => ({
        open: () => setShow(true),
        close: () => setShow(false),
    }))

    return (
        <Dialog open={show} onOpenChange={(open) => setShow(open)}>
            <DialogContent>
                <div className="w-full flex items-center justify-center  align-middle mb-6">
                    <img src="/secure-feature.svg" alt="Locked Feature" className="w-60" />
                </div>
                <DialogTitle>Upgrade your plan for more company credits.</DialogTitle>
                <DialogDescription>
                    Company data cannot be revealed due to lack of company credits in your account.
                </DialogDescription>
                <div className="flex items-center justify-center mt-6">
                    <UpgradeButton label="View pricing plans" showIcon={false} variant="default" />
                </div>
            </DialogContent>
        </Dialog>
    )
})

export default OutOfCreditsDialog
