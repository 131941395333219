import { useEffect, useMemo, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import debounce from 'lodash.debounce'
import * as z from 'zod'
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/ui/base/form'
import { Button } from '@/shared/ui/base/button'
import { Checkbox } from '@/shared/ui/base/checkbox'
import { FormLabel } from '@/components/ui/base/form'
import { useQuery } from '@tanstack/react-query'
import { Industry, IndustryNested } from '@/schemas/entities/industry.schema'
import { buildIndustryHierarchy, getAllIndustries } from '@/services/industry.service'
import { WEEK_IN_MS } from '@/shared/utils/date-utils'
import { Spinner } from '../../../shared/ui/base/spinner'
import { areObjectsEqual } from '@/lib/search-params-utils'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../layout/components/accordion'
import { formatNumberWithComma } from '@/shared/utils/number-utils'
import { Cross2Icon } from '@radix-ui/react-icons'
import { Tag } from './components/tag'

const FormSchema = z.object({
    items: z.array(z.number()),
})

export function SearchFilterValuePreviewIndustry({ value }: { value: number[] }) {
    const { data: industries = [] } = useQuery<Industry[]>({
        queryKey: ['industries'],
        queryFn: getAllIndustries,
        staleTime: WEEK_IN_MS,
    })
    const length = value ? value.length : 0
    const valueLabels = value
        ? value.slice(0, 1).map((item) => industries.find((value) => value.industry_id === item)?.industry)
        : []
    const valueString = valueLabels ? valueLabels.join(', ') : ''
    return (
        <p className="text-sm text-ellipsis hyphens-auto text-start max-w-80 text-nowrap overflow-hidden">{`${valueString}${length > 1 ? `, + ${length - 1}` : ''}`}</p>
    )
}

const filterIndustries = (nestedIndustries: IndustryNested[], query: string): IndustryNested[] => {
    return nestedIndustries
        .map((industry) => {
            const children = filterIndustries(industry.children, query)
            if (industry.industry.toLowerCase().includes(query.toLowerCase()) || children.length > 0) {
                return { ...industry, children: children.length > 0 ? children : [] }
            }
            return null
        })
        .filter((industry) => industry !== null) as IndustryNested[]
}

export function SearchFilterFormIndustry({
    value,
    onChangeValue,
    onInteractOutside,
    closePopover,
}: {
    value: number[]
    onChangeValue: (_value: number[] | undefined) => void
    onInteractOutside: boolean
    closePopover: () => void
}) {
    const [searchQuery, setSearchQuery] = useState('')
    const [nestedIndustries, setNestedIndustries] = useState<IndustryNested[]>([])
    const [filteredNestedIndustries, setFilteredNestedIndustries] = useState<IndustryNested[]>([])
    const [accordionValues, setAccordionValues] = useState<string[]>([])
    const [selectedValues, setSelectedValues] = useState<Industry[]>([])
    const { data: industries = [], isLoading } = useQuery<Industry[]>({
        queryKey: ['industries'],
        queryFn: getAllIndustries,
        staleTime: WEEK_IN_MS,
    })

    useEffect(() => {
        if (isLoading) return
        const nestedIndustries = buildIndustryHierarchy(industries)
        setNestedIndustries(nestedIndustries)
    }, [isLoading])

    const form = useForm<z.infer<typeof FormSchema>>({
        resolver: zodResolver(FormSchema),
        defaultValues: {
            items: value || [],
        },
    })

    const formCurrentValue = useWatch({ control: form.control, name: 'items' })

    useEffect(() => {
        if (!formCurrentValue) return
        const selected = industries.filter((item) => formCurrentValue.includes(item.industry_id))
        if (!areObjectsEqual(selected, selectedValues)) {
            setSelectedValues(selected)
        }
    }, [formCurrentValue, industries])

    useEffect(() => {
        if (onInteractOutside === true) {
            onSubmit(form.getValues())
        }
    }, [onInteractOutside])

    function onSubmit(data: z.infer<typeof FormSchema> | undefined) {
        const newValue = data && data.items.length > 0 ? data.items : undefined
        if (areObjectsEqual(newValue, value)) {
            closePopover()
            return
        }
        onChangeValue(newValue)
    }

    useEffect(() => {
        if (searchQuery.length === 0) {
            setFilteredNestedIndustries(nestedIndustries)
            setAccordionValues([])
        } else {
            setFilteredNestedIndustries(filterIndustries(nestedIndustries, searchQuery))
            setAccordionValues(industries.map((industry) => industry.industry_id.toString()))
        }
    }, [searchQuery, nestedIndustries])

    const handleChange = (e: any) => {
        setSearchQuery(e.target.value)
    }

    const debouncedResults = useMemo(() => {
        return debounce(handleChange, 300)
    }, [])

    useEffect(() => {
        return () => {
            debouncedResults.cancel()
        }
    })

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="grid max-w-sm items-center gap-y-4">
                <div
                    className={`relative flex flex-col items-start border rounded  max-h-40 overflow-y-auto ${selectedValues.length > 0 ? 'p-2' : 'px-3'}`}
                    cmdk-input-wrapper=""
                >
                    <div className="flex flex-row gap-1 flex-wrap">
                        {selectedValues.map((item) => (
                            <Tag
                                key={item.industry_id}
                                id={item.industry_id}
                                name={item.industry}
                                onClick={(industry_id) => {
                                    form.setValue(
                                        'items',
                                        form.getValues('items').filter((id) => id !== industry_id)
                                    )
                                }}
                            />
                        ))}
                        <input
                            type="text"
                            autoFocus={true}
                            onChange={debouncedResults}
                            placeholder="Search..."
                            className="h-8 min-w-12 rounded-md bg-transparent py-3 text-sm outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50"
                        />
                    </div>
                    {selectedValues.length > 0 && (
                        <Cross2Icon
                            className="absolute right-2 top-3 h-4 w-4 cursor-pointer text-slate-500 hover:text-black"
                            onClick={() => form.setValue('items', [])}
                        />
                    )}
                </div>
                <FormField
                    control={form.control}
                    name="items"
                    render={() => (
                        <FormItem className="max-h-[300px] overflow-scroll">
                            <FormMessage />

                            <Accordion
                                type="multiple"
                                className="w-full"
                                value={accordionValues}
                                onValueChange={setAccordionValues}
                            >
                                {filteredNestedIndustries.map((item) => (
                                    <ParentIndustryAccordionItem
                                        key={item.industry_id}
                                        industry={item}
                                        form={form}
                                        is_filtering={searchQuery.length > 0}
                                        allNestedIndustries={nestedIndustries}
                                    />
                                ))}
                            </Accordion>
                            {isLoading && (
                                <div className="flex justify-center">
                                    <Spinner size="sm" />
                                </div>
                            )}
                            {!isLoading && filteredNestedIndustries.length === 0 && (
                                <div className="flex justify-center">
                                    <p className="text-sm text-gray-500 text-center">
                                        No results found for "{searchQuery}"
                                    </p>
                                </div>
                            )}
                        </FormItem>
                    )}
                />
                <Button type="submit" size="sm">
                    Save
                </Button>
            </form>
        </Form>
    )
}

function getAllChildIds(industry: IndustryNested): number[] {
    let ids = industry.children.map((child) => child.industry_id)
    industry.children.forEach((child) => {
        ids = ids.concat(getAllChildIds(child))
    })
    return ids
}

const findIndustryById = (industries: IndustryNested[], id: number): IndustryNested | undefined => {
    for (const industry of industries) {
        if (industry.industry_id === id) return industry
        const found = findIndustryById(industry.children, id)
        if (found) return found
    }
    return undefined
}

function ParentIndustryAccordionItem({
    industry,
    form,
    is_filtering,
    allNestedIndustries,
}: {
    industry: IndustryNested
    form: any
    is_filtering: boolean
    allNestedIndustries: IndustryNested[]
}) {
    const isChecked = (industry: IndustryNested) => {
        const completeIndustry = findIndustryById(allNestedIndustries, industry.industry_id)
        if (!completeIndustry) return false
        const allChildIds = getAllChildIds(completeIndustry)
        const checkedChildren = allChildIds.filter((id) => form.getValues('items').includes(id))
        if (checkedChildren.length === allChildIds.length) return true
        if (checkedChildren.length > 0) return 'indeterminate'
        return false
    }

    const handleCheckedChange = (checked: boolean) => {
        const completeIndustry = findIndustryById(allNestedIndustries, industry.industry_id)
        if (!completeIndustry) return false
        const childIds = getAllChildIds(completeIndustry)
        const currentItems = form.getValues('items')
        const newItems = checked
            ? [...new Set([...currentItems, ...childIds])]
            : currentItems.filter((id: number) => !childIds.includes(id))
        form.setValue('items', newItems)
    }

    const has_children = industry.children.length > 0 && industry.children.some((child) => child.children.length > 0)

    return (
        <AccordionItem className="border-0" value={industry.industry_id.toString()} key={industry.industry_id}>
            <div className="flex justify-start items-start gap-2 py-1">
                {has_children && (
                    <>
                        <AccordionTrigger className="py-0 items-center pt-1" />
                        <div className="flex flex-row items-start space-x-2 space-y-0">
                            <Checkbox
                                id={industry.industry_id.toString()}
                                className="mt-0.5"
                                checked={isChecked(industry)}
                                onCheckedChange={handleCheckedChange}
                            />
                            <div className="text-sm font-normal text-start">
                                <label
                                    className="text-sm font-normal hover:underline cursor-pointer"
                                    htmlFor={industry.industry_id.toString()}
                                >
                                    {industry.industry}{' '}
                                    <span className="text-gray-500 text-xs">
                                        ({formatNumberWithComma(industry.companies)})
                                    </span>
                                </label>
                            </div>
                        </div>
                    </>
                )}
                {!has_children && (
                    <>
                        <span className="ml-4" />
                        <IndustryOptionCheckItem
                            industry_id={industry.industry_id}
                            industry_name={industry.industry}
                            n_companies={industry.companies}
                            form={form}
                        />
                    </>
                )}
            </div>
            {has_children && (
                <AccordionContent className="pl-6 pb-0">
                    {industry.children.map((child) => (
                        <ParentIndustryAccordionItem
                            key={child.industry_id}
                            industry={child}
                            form={form}
                            is_filtering={is_filtering}
                            allNestedIndustries={allNestedIndustries}
                        />
                    ))}
                </AccordionContent>
            )}
        </AccordionItem>
    )
}

function IndustryOptionCheckItem({
    industry_id,
    industry_name,
    n_companies,
    form,
}: {
    industry_id: number
    industry_name: string
    n_companies: number
    form: any
}) {
    return (
        <FormField
            key={industry_id}
            control={form.control}
            name="items"
            render={({ field }) => {
                return (
                    <FormItem key={industry_id} className="flex flex-row items-start space-x-2 space-y-0">
                        <FormControl>
                            <Checkbox
                                id={industry_id.toString()}
                                className="mt-0.5"
                                checked={field.value?.includes(industry_id)}
                                onCheckedChange={(checked) => {
                                    return checked
                                        ? field.onChange([...(field.value || []), industry_id])
                                        : field.onChange(field.value?.filter((value: number) => value !== industry_id))
                                }}
                            />
                        </FormControl>
                        <FormLabel
                            htmlFor={industry_id.toString()}
                            className="text-sm font-normal text-start cursor-pointer hover:underline"
                        >
                            <p className="text-sm font-normal">
                                {industry_name}{' '}
                                <span className="text-gray-500 text-xs">({formatNumberWithComma(n_companies)})</span>
                            </p>
                        </FormLabel>
                    </FormItem>
                )
            }}
        />
    )
}
