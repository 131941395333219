import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '@/shared/ui/base/command'
import { MultiSelectValues } from '../../../shared/schemas/entity/filter.schema'
import { Check } from 'lucide-react'

export function SearchFilterValuePreviewSelect({
    value,
    availableValues,
}: {
    value: string
    availableValues: MultiSelectValues
}) {
    const label = availableValues.find((item) => item.id === value)?.label
    return <p className="text-sm">{label}</p>
}

export function SearchFilterFormSelect({
    value,
    onChangeValue,
    availableValues,
}: {
    onChangeValue: (value: string) => void
    availableValues: MultiSelectValues
    value: string
}) {
    const onChangeCondition = (newValue: string) => {
        if (newValue == value) return
        onChangeValue(newValue)
    }
    return (
        <Command>
            {availableValues.length > 5 && <CommandInput placeholder="Type filter..." />}
            <CommandList>
                <CommandEmpty>No results found.</CommandEmpty>
                <CommandGroup>
                    {availableValues.map((item) => (
                        <CommandItem
                            key={item.id}
                            onSelect={() => onChangeCondition(item.id)}
                            disabled={value == item.id}
                        >
                            {value == item.id && <Check className="h-4 w-4 mr-2" />}
                            {value != item.id && <div className="h-4 w-4 mr-2" />}
                            <span>{item.label}</span>
                        </CommandItem>
                    ))}
                </CommandGroup>
            </CommandList>
        </Command>
    )
}
