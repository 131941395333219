import { Company } from '@/shared/schemas/entity/company.schema'
import { Table } from '@tanstack/react-table'
import { useMemo } from 'react'

export function useCompanyAction<TData>(
    isDataFetching: boolean,
    table: Table<TData>,
    fromRowToCompany: (rows: TData[]) => Company[]
) {
    const companies = useMemo(() => {
        if (
            table.options?.meta?.getBulkSelectionData !== undefined &&
            table.options?.meta?.getBulkSelectionData().length > 0
        ) {
            return fromRowToCompany(table.options?.meta?.getBulkSelectionData())
        }
        return fromRowToCompany(table.getSelectedRowModel().rows.map((row) => row.original))
    }, [
        table.getSelectedRowModel().rows,
        table.options?.meta?.getBulkSelectionData,
        table.options?.meta?.getBulkSelectionData(),
        fromRowToCompany,
    ])

    const disableReason = useMemo(() => {
        if (isDataFetching) return 'Wait until the data is loaded'
        if (companies.length === 0) return 'Select some companies first'
        if (companies.some((company) => company.has_blurred_data === true))
            return 'Reveal selected to enable bulk actions'
        return undefined
    }, [isDataFetching, companies])

    const isActionDisabled = useMemo(() => {
        return disableReason !== undefined
    }, [disableReason])

    return { isActionDisabled, disableReason, companies }
}
