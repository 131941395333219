import { useAuthentication } from '@/components/hooks/authentication/use-authentication'
import { initTracking } from '@/services/tracking.service'
import posthog from 'posthog-js'
import React from 'react'

export const AuthenticationGuard = ({ component: Component }: { component: React.ComponentType }) => {
    const { isAuthenticated, isLoading, user, register } = useAuthentication()

    if (isLoading) {
        return <></>
    }

    if (isLoading === false && isAuthenticated === false) {
        const authOptions = { app_state: { redirectTo: window.location.pathname + window.location.search } }
        posthog.reset()
        register(authOptions)
    }

    if (user) {
        initTracking(user)
    }

    return isAuthenticated ? <Component /> : <></>
}
