import { cn } from '@/lib/utils'

export type ConfidenceLevel = 'high' | 'medium' | 'low'

export function ConfidenceLevelChart({ confidence, className }: { confidence: ConfidenceLevel; className?: string }) {
    const color = confidence == 'high' ? '#2d766b' : confidence == 'medium' ? '#fdba74' : '#f87171'
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={cn('lucide lucide-bar-chart w-6 h-6', className)}
        >
            <line x1="18" x2="18" y1="20" y2="4" stroke={confidence == 'high' ? color : '#7d7d7d'} />
            <line x1="12" x2="12" y1="20" y2="10" stroke={confidence != 'low' ? color : '#7d7d7d'} />
            <line x1="6" x2="6" y1="20" y2="16" stroke={color} />
        </svg>
    )
}
