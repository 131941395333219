import { Button } from '../../../../shared/ui/base/button'
import { UserWithTeam } from '@/schemas/entities/user'
import { getMyProfile } from '@/services/user.service'
import { Rocket } from 'lucide-react'
import { Dialog, DialogContent, DialogTitle, DialogTrigger } from '@/shared/ui/base/dialog'
import { useQuery } from '@tanstack/react-query'
import { EVENT_UPGRADE_MODAL_VIEW, sendEvent } from '@/services/tracking.service'
import { ScrollArea } from '../../base/scroll-area'
import PricingGrid from './pricing_grid'
import { THERE_MINUTE_IN_MS } from '@/shared/utils/date-utils'
import * as VisuallyHidden from '@radix-ui/react-visually-hidden'

export type UpgradeButtonProps = {
    label?: string
    showIcon?: boolean
    variant?: 'default' | 'outline'
    mode?: 'both' | 'one_time' | 'recurring'
    size?: 'default' | 'xs' | 'sm' | 'lg'
    defaultTab?: 'app' | 'api' | 'dataset'
}

export default function UpgradeButton({
    label = 'Upgrade',
    showIcon = true,
    variant = 'outline',
    mode = 'both',
    size = 'default',
    defaultTab = 'app',
}: UpgradeButtonProps) {
    const { data: me = null } = useQuery<UserWithTeam>({
        queryKey: ['me'],
        queryFn: getMyProfile,
        staleTime: THERE_MINUTE_IN_MS,
    })
    return (
        <>
            {me?.team && (
                <Dialog
                    onOpenChange={(open) => {
                        if (open) sendEvent(EVENT_UPGRADE_MODAL_VIEW)
                    }}
                >
                    <DialogTrigger asChild>
                        <Button variant={me.team.credits_left_current_period == 0 ? 'default' : variant} size={size}>
                            {showIcon && <Rocket className="w-5 h-5 mr-2" />} {label}
                        </Button>
                    </DialogTrigger>
                    <DialogContent className="sm:max-w-[1225px]" aria-describedby={undefined}>
                        <VisuallyHidden.Root asChild>
                            <DialogTitle>Show credits</DialogTitle>
                        </VisuallyHidden.Root>
                        <ScrollArea style={{ maxHeight: 'calc(100vh - 20vh)' }}>
                            <PricingGrid mode={mode} defaultTab={defaultTab} />
                        </ScrollArea>
                    </DialogContent>
                </Dialog>
            )}
        </>
    )
}
