import { getImpersonatingEmail } from '@/services/auth/auth.service'
import { useEffect, useState } from 'react'

export function useImpersonating() {
    const [impersonatingEmail, setImpersonatingEmail] = useState<string | null>(null)

    useEffect(() => {
        const impersonatedEmail = getImpersonatingEmail()
        if (impersonatedEmail) {
            setImpersonatingEmail(impersonatedEmail)
        }
    }, [])

    return { impersonatingEmail, isImpersonating: impersonatingEmail != null }
}
