import { Link, NavLink } from 'react-router-dom'
import { Home, List, Search } from 'lucide-react'
import { COMPANY_LIST_PATH, COMPANY_SEARCH_NEW_PATH, HOME_PATH } from '@/paths'
import { Separator } from '../../../../shared/ui/base/separator'
;('lucide-react')

export const MainNavLinks = [
    {
        name: 'Home',
        href: HOME_PATH,
        icon: Home,
    },
    {
        name: 'Search',
        href: COMPANY_SEARCH_NEW_PATH,
        icon: Search,
    },
    {
        name: 'Lists',
        href: COMPANY_LIST_PATH,
        icon: List,
    },
]

export function DesktopNavLinks({ handleOnClick = () => {} }: { handleOnClick?: () => void }) {
    return (
        <>
            {MainNavLinks.map((link) => (
                <NavLink
                    key={link.name}
                    to={link.href}
                    onClick={handleOnClick}
                    className={({ isActive }) =>
                        `inline-flex items-center ${isActive ? 'text-primary rounded-md bg-secondary' : 'text-muted-foreground hover:text-primary hover:bg-gray-100 hover:rounded-md'}`
                    }
                >
                    <div className="transition-colors flex items-center space-x-2 p-2">
                        <link.icon className="h-5 w-5" />
                        <p className="text-sm font-semibold ">{link.name}</p>
                    </div>
                </NavLink>
            ))}
        </>
    )
}

export function MobileNavLinks({ handleOnClick = () => {} }: { handleOnClick?: () => void }) {
    return (
        <>
            {MainNavLinks.map((link, index) => (
                <div key={link.name}>
                    <Link
                        to={link.href}
                        className="-mx-3 block rounded-lg px-3 py-4 text-base font-medium leading-7  hover:underline"
                        onClick={() => handleOnClick()}
                    >
                        {link.name}
                    </Link>
                    {index < MainNavLinks.length - 1 && <Separator />}
                </div>
            ))}
        </>
    )
}
