import { ColumnDef, Table } from '@tanstack/react-table'

import { DataTable } from '@/components/ui/table/data-table'
import { DataTablePagination } from '@/components/ui/table/data-table-pagination'
import { Request } from '@/schemas/entities/recent-search.schema'

interface DataTableProps {
    table: Table<Request>
    columns: ColumnDef<Request>[]
    isDataFetching: boolean
    totalResults: number
}

export function DataTableWithPagination({ table, columns, isDataFetching, totalResults }: DataTableProps) {
    return (
        <>
            <DataTable table={table} columns={columns} isDataFetching={isDataFetching} />
            <DataTablePagination table={table} totalResults={totalResults} />
        </>
    )
}
