import { TechnologyCategory } from '@/schemas/entities/technology-category'

export async function getAllTechnologyCategories(): Promise<TechnologyCategory[]> {
    const url = `${import.meta.env.VITE_THEIRSTACK_API_SERVER_URL}/v0/catalog/technologies/categories`
    const res = await fetch(url, { method: 'GET', headers: { Accept: 'application/json' } })
    if (!res.ok) {
        throw new Error('Failed to fetch data')
    }
    return ((await res.json()) as { data: TechnologyCategory[] }).data
}
