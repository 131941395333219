import { Input } from '@/components/ui/base/input'
import ExportButton from '@/components/ui/table/actions/action-export-data/export-button'
import FindPeopleButton from '@/components/ui/table/actions/action-find-people'
import { CompanyFromList } from '@/schemas/entities/companies-from-company-list'
import { CompanyFromListSearchParams } from '@/schemas/request/companies-from-list-search-params.schema'
import { companySchema } from '@/shared/schemas/entity/company.schema'
import { Table } from '@tanstack/react-table'
import debouce from 'lodash.debounce'
import { useEffect, useMemo, useState } from 'react'
import { fromCompanyListToCSVLines } from './company-list-exporter'

interface DataTableToolbarProps {
    table: Table<CompanyFromList>
    filter_by?: string
    isDataFetching: boolean
    onChangeFilters: (_params: CompanyFromListSearchParams) => void
    searchedSP: CompanyFromListSearchParams
}

export function DataTableToolbar({ table, isDataFetching, onChangeFilters, searchedSP }: DataTableToolbarProps) {
    const [searchQuery, setSearchQuery] = useState('')

    const debouncedResults = useMemo(() => {
        return debouce((e) => setSearchQuery(e.target.value), 400)
    }, [])

    useEffect(() => {
        const searchParams = { ...searchedSP, company_name_partial: searchQuery }
        onChangeFilters(searchParams)
    }, [searchQuery])

    return (
        <>
            <div className="flex flex-wrap justify-between  items-end gap-2 content-end">
                <Input
                    placeholder="Filter by company name..."
                    onChange={debouncedResults}
                    className="h-8 w-[150px] lg:w-[250px]"
                />
                <div className="flex flex-grap gap-1 border rounded-md bg-white">
                    <ExportButton<CompanyFromList>
                        recordName="companies"
                        reportName="companies"
                        isDataFetching={isDataFetching}
                        table={table}
                        fromRecordToCSVLines={fromCompanyListToCSVLines}
                        isRowBlurred={() => false}
                    />
                    <FindPeopleButton
                        isDataFetching={isDataFetching}
                        table={table}
                        fromRowToCompany={(rows) => rows.map((row) => companySchema.parse(row.company_object))}
                    />
                </div>
            </div>
        </>
    )
}
