import React, { useEffect } from 'react'
import { Card } from '@/shared/ui/base/card'
import CompanyListCard from '../home/components/company-list/company-list-card'
import { PageLayout } from '@/components/ui/layout/page_layout'

const CompanylistPage: React.FC = () => {
    useEffect(() => {
        document.title = 'Home · TheirStack'
    }, [])

    return (
        <PageLayout className="bg-gray-100">
            <div className="max-w-3xl m-auto">
                <div className="flex flex-col gap-8 items-center">
                    <div className="w-full">
                        <p className="text-sm text-gray-700 mb-4">Your company lists</p>
                        <Card className="w-full p-4">
                            <CompanyListCard />
                        </Card>
                    </div>
                </div>
            </div>
        </PageLayout>
    )
}

export default CompanylistPage
