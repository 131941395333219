import { UserWithTeam } from '@/schemas/entities/user'
import { getMyProfile } from '@/services/user.service'
import { THERE_MINUTE_IN_MS } from '@/shared/utils/date-utils'
import { useQuery } from '@tanstack/react-query'

export function useMe() {
    const { data: me = null } = useQuery<UserWithTeam>({
        queryKey: ['me'],
        queryFn: getMyProfile,
        staleTime: THERE_MINUTE_IN_MS,
    })
    return { me }
}
