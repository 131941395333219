'use client'

import { BillingProduct } from '@/shared/schemas/entity/billing-product.schema'
import { Currency } from '@/shared/schemas/response/geolocation.schema'
import { DatabaseZap, Laptop, SquareChevronRight } from 'lucide-react'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../base/tabs'
import APIPricing from './api/api-pricing'
import AppPricing from './app/app-pricing'
import DatasetPricing from './dataset/dataset-pricing'

export default function PricingBox({
    mode = 'both',
    defaultTab = 'app',
    products,
    currency,
    onBillingPriceSelected,
    className,
    showFreePlan = false,
}: {
    mode: 'both' | 'one_time' | 'recurring'
    defaultTab?: 'app' | 'api' | 'dataset'
    products: BillingProduct[]
    currency: Currency
    onBillingPriceSelected: (price_id: string | undefined) => void
    className?: string
    showFreePlan?: boolean
}) {
    const appProducts = products.filter((product) => !product.is_api_available)
    const apiProduct = products.filter((product) => product.is_api_available)[0]

    return (
        <div className={`w-full mt-6 bg-white rounded-md border ${className}`}>
            <Tabs defaultValue={defaultTab}>
                <TabsList className="grid w-full h-auto p-0  grid-cols-3">
                    <TabsTrigger
                        value="app"
                        className="grid grid-flow-row auto-rows-max md:justify-start h-auto data-[state=active]:shadow-none rounded-b-none py-2 border-black rounded-none first:rounded-tl-md last:rounded-tr-md  border-r"
                    >
                        <div className="flex flex-row items-center justify-start gap-2">
                            <Laptop className="w-8 h-8 hidden md:block" />
                            <div className="flex flex-col justify-start items-start">
                                <p className="text-base md:text-lg font-semibold text-wrap">
                                    <span className="hidden md:inline-block">TheirStack</span> UI
                                </p>
                                <p className="text-xs text-gray-500 text-wrap hidden md:block">app.theirstack.com</p>
                            </div>
                        </div>
                    </TabsTrigger>

                    <TabsTrigger
                        value="api"
                        className="grid grid-flow-row auto-rows-max md:justify-start h-auto data-[state=active]:shadow-none rounded-b-none py-2 border-black rounded-none first:rounded-tl-md last:rounded-tr-md border-r "
                    >
                        <div className=" flex flex-row items-center gap-2">
                            <SquareChevronRight className="w-8 h-8 hidden md:block" />
                            <div className="flex flex-col justify-start items-start">
                                <p className="text-base md:text-lg font-semibold text-wrap">API</p>
                                <p className="text-xs text-gray-500 text-wrap hidden md:block">api.theirstack.com</p>
                            </div>
                        </div>
                    </TabsTrigger>

                    <TabsTrigger
                        value="dataset"
                        className="grid grid-flow-row auto-rows-max md:justify-start h-auto data-[state=active]:shadow-none rounded-b-none py-2 border-black rounded-none first:rounded-tl-md last:rounded-tr-md "
                    >
                        <div className="flex flex-row items-center gap-2">
                            <DatabaseZap className="w-7 h-8 hidden md:block" />
                            <div className="flex flex-col justify-start items-start">
                                <p className="text-base md:text-lg font-semibold text-wrap">Datasets</p>
                                <p className="text-xs text-gray-500 text-wrap hidden md:block">
                                    datasets.theirstack.com
                                </p>
                            </div>
                        </div>
                    </TabsTrigger>
                </TabsList>
                <TabsContent value="app" className="bg-white p-4 rounded-md shadow-sm">
                    <AppPricing
                        mode={mode}
                        products={appProducts}
                        currency={currency}
                        onBillingPriceSelected={onBillingPriceSelected}
                        showFreePlan={showFreePlan}
                    />
                </TabsContent>

                <TabsContent value="api" className="bg-white p-4 rounded-md shadow-sm">
                    {apiProduct && (
                        <APIPricing
                            product={apiProduct}
                            currency={currency}
                            onBillingPriceSelected={onBillingPriceSelected}
                            showFreePlan={showFreePlan}
                        />
                    )}
                </TabsContent>
                <TabsContent value="dataset" className="bg-white p-4 rounded-md shadow-sm">
                    {apiProduct && (
                        <DatasetPricing
                            product={apiProduct}
                            currency={currency}
                            onBillingPriceSelected={onBillingPriceSelected}
                        />
                    )}
                </TabsContent>
            </Tabs>
        </div>
    )
}
