import SavedSearchCard from '@/pages/home/components/saved-searches/saved-search-card'
import { Card } from '../../../shared/ui/base/card'
import { SearchType } from '@/schemas/entities/recent-search.schema'
import RecentSearchesCard from '@/pages/home/components/saved-searches/recent-searches-card'

export default function DataTableInitialState({ search_type }: { search_type: SearchType }) {
    return (
        <div className="grow ">
            <div className="hidden md:flex justify-between px-10">
                <img
                    src="/add-filters-to-refine-your-search.png"
                    alt="Add filters to refine your search"
                    className="w-64  object-scale-down"
                />
                {search_type == 'jobs' && (
                    <img
                        src="/search-to-display-results.png"
                        alt="Add filters to refine your search"
                        className="w-60 object-scale-down"
                    />
                )}
            </div>
            <div className="mx-auto mt-4 w-full md:w-11/12 border rounded-md bg-gray-50 p-8">
                <div className="flex flex-col w-full">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 place-items-start">
                        <div className="flex flex-col w-full h-full">
                            <p className="text-sm text-gray-700 mb-4">Saved searches</p>
                            <Card className="grow w-full h-full p-4">
                                <SavedSearchCard show_filters={false} search_type={search_type} />
                            </Card>
                        </div>
                        <div className="flex flex-col w-full h-full">
                            <p className="text-sm text-gray-700 mb-4">Recent searches</p>
                            <Card className="grow w-full h-full p-4">
                                <RecentSearchesCard search_type={search_type} />
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
