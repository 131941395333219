import { useRevealCompany } from '@/components/hooks/company/use-reveal-company'
import CompanyModal from '@/components/ui/company/company-modal/company-modal'
import { ConfirmRevealCompanyDialog } from '@/components/ui/dialog/confirm-reveal-company-dialog'
import { OpenDialogHandle } from '@/components/ui/dialog/dialog-handle-interface'
import OutOfCreditsDialog from '@/components/ui/dialog/out-of-credits-dialog'
import { FullCompany } from '@/schemas/entities/full-company.schema'
import { Card } from '@/shared/ui/base/card'
import { HoverCard, HoverCardContent, HoverCardTrigger } from '@/shared/ui/base/hover-card'
import { WithTooltip } from '@/shared/ui/base/tooltip'
import { formatCompactNumber, formatNumberWithComma } from '@/shared/utils/number-utils'
import { useRef } from 'react'
import { HoverTechnologyCard } from './hover-technology-card'
import TechnologyCard from './technology-card'

export default function TechnologyColumn({
    company,
    updateCompany,
}: {
    company: FullCompany
    updateCompany: (company: FullCompany) => void
}) {
    const outOfCreditsDialogRef = useRef<OpenDialogHandle>(null)
    const { revealCompany } = useRevealCompany({
        company,
        updateCompany,
        postRevealAction: () => companyModalRef?.current?.open(),
        showUpgradeDialog: () => outOfCreditsDialogRef.current?.open(),
    })
    const companyModalRef = useRef<OpenDialogHandle>(null)
    const n_tecnologies = company.technology_slugs?.length || 0
    return (
        <>
            {company.technologies_found?.length == 0 && (
                <p className="max-w-[300px] text-clip font-normal">
                    {formatCompactNumber(company.technology_slugs?.length || 0)}
                </p>
            )}
            {company.technologies_found.length > 0 && (
                <div className="flex items-center space-x-1 align-middle">
                    {company.technologies_found
                        .sort((a, b) => {
                            const confidenceValues = { low: 1, medium: 2, high: 3 }
                            if (confidenceValues[b.confidence] !== confidenceValues[a.confidence]) {
                                return confidenceValues[b.confidence] - confidenceValues[a.confidence]
                            }
                            // Sort by last_date_found in descending order if confidence is the same
                            return new Date(b.last_date_found).getTime() - new Date(a.last_date_found).getTime()
                        })
                        .map((techdetails) => (
                            <HoverCard key={techdetails.technology.slug} openDelay={50} closeDelay={0}>
                                <HoverCardTrigger>
                                    <TechnologyCard techdetails={techdetails} />
                                </HoverCardTrigger>
                                <HoverCardContent className="w-80">
                                    <HoverTechnologyCard
                                        company={company}
                                        techdetails={techdetails}
                                        updateCompany={updateCompany}
                                    />
                                </HoverCardContent>
                            </HoverCard>
                        ))}
                    {n_tecnologies - company.technologies_found?.length > 0 && (
                        <>
                            <CompanyModal ref={companyModalRef} company={company} default_tab="technologies" />
                            {company.has_blurred_data == true && (
                                <ConfirmRevealCompanyDialog confirmAction={revealCompany} n_companies={1}>
                                    <Card
                                        className="p-1 flex items-center gap-2 cursor-pointer hover:bg-gray-200"
                                        style={{ height: 34 }}
                                    >
                                        <p className="font-normal text-sm whitespace-nowrap">
                                            {' '}
                                            +{' '}
                                            {formatNumberWithComma(
                                                n_tecnologies - company.technologies_found?.length
                                            )}{' '}
                                        </p>
                                    </Card>
                                </ConfirmRevealCompanyDialog>
                            )}
                            {company.has_blurred_data == false && (
                                <WithTooltip title="View all technologies">
                                    <Card
                                        className="p-1 flex items-center gap-2 cursor-pointer hover:bg-gray-200"
                                        style={{ height: 34 }}
                                        onClick={() => companyModalRef.current?.open()}
                                    >
                                        <p className="font-normal text-sm whitespace-nowrap">
                                            {' '}
                                            +{' '}
                                            {formatNumberWithComma(
                                                n_tecnologies - company.technologies_found?.length
                                            )}{' '}
                                        </p>
                                    </Card>
                                </WithTooltip>
                            )}
                        </>
                    )}
                </div>
            )}
            <OutOfCreditsDialog ref={outOfCreditsDialogRef} />
        </>
    )
}
