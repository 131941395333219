export const HighlightKeywords = ({ text, keywords }: { text: string; keywords: string[] }) => {
    // Escape special characters in keywords to avoid issues in regex
    const escapeRegExp = (text: string) => text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

    // Sort keywords by length in descending order to match longer keywords first
    const sortedKeywords = keywords.sort((a, b) => b.length - a.length)

    // Create a regex to find all keywords
    const regex = new RegExp(sortedKeywords.map(escapeRegExp).join('|'), 'gi')

    // Split the text into parts and wrap keywords with the <mark> tag
    const parts = text.split(regex).reduce((acc: (JSX.Element | string)[], current: string, index: number) => {
        // Push current non-keyword text
        acc.push(current)

        // If this is not the last part, add the next keyword (it's between current and the next part)
        if (index < text.split(regex).length - 1) {
            // Find the keyword that matches the gap between current and the next part

            const match = text.match(regex)![index]
            acc.push(<mark key={index}>{match}</mark>)
        }

        return acc
    }, [])

    return <>{parts}</>
}
