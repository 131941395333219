import { Button } from '@/shared/ui/base/button'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { DateRange } from 'react-day-picker'
import { Calendar } from '../base/calendar'
import { DATES_SEPARATOR, fromStringToDateRange } from '@/shared/utils/date-utils'

export function SearchFilterValuePreviewBetweenDates({ value }: { value: string }) {
    const [fromDate, toDate] = value.split(DATES_SEPARATOR)
    const styled_value = `${format(new Date(fromDate), 'MMM d, yyyy')} - ${format(new Date(toDate), 'MMM d, yyyy')}`
    return <p className="text-sm">{styled_value}</p>
}

export function SearchFilterFormBetweenDates({
    value,
    onChangeValue,
    onInteractOutside,
    closePopover,
}: {
    id: string
    value: string
    onChangeValue: (_value: string) => void
    onInteractOutside: boolean
    closePopover: () => void
}) {
    const [date, setDate] = useState<DateRange | undefined>()
    useEffect(() => {
        if (onInteractOutside === true) {
            onSubmit()
        }
    }, [onInteractOutside])

    useEffect(() => {
        if (value) {
            setDate(fromStringToDateRange(value))
        }
    }, [value])

    function onSubmit() {
        if (!date || !date.from || !date.to) return
        const fromDate = format(date.from, 'yyyy-MM-dd')
        const toDate = format(date.to, 'yyyy-MM-dd')
        const newValue = fromDate + DATES_SEPARATOR + toDate
        if (newValue == value) {
            closePopover()
            return
        }
        onChangeValue(newValue)
    }

    return (
        <div className="grid">
            <Calendar
                initialFocus
                mode="range"
                defaultMonth={date?.from}
                selected={date}
                onSelect={setDate}
                numberOfMonths={2}
            />
            <Button onClick={onSubmit} size="sm" className="max-w">
                Save
            </Button>
        </div>
    )
}
