import { Button } from '@/shared/ui/base/button'
import { Table } from '@tanstack/react-table'

import { z } from 'zod'

import { useMe } from '@/components/hooks/use-me'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/base/form'
import { Input } from '@/components/ui/base/input'
import { RadioGroup, RadioGroupItem } from '@/components/ui/base/radio-group'
import { Spinner } from '@/shared/ui/base/spinner'
import CreditsBadge from '@/shared/ui/credits/credits-badge'
import { formatNumberWithComma } from '@/shared/utils/number-utils'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { NotEnoughCredits } from './not-enough-credits'
import { useCalculateOperationCreditsCost } from './use-calculate-operation-credits-cost'

export const ExportFormSchema = z
    .object({
        format: z.enum(['csv', 'xlsx'], {
            required_error: 'You need to select a format.',
        }),
        numberOfRecords: z.enum(['this_page', 'selection', 'all', 'custom'], {
            required_error: 'You need to select the number of companies.',
        }),
        custom_number_records: z.coerce.number().min(1).optional(),
    })
    .superRefine((data, ctx) => {
        if (
            data.numberOfRecords === 'custom' &&
            (data.custom_number_records === undefined || data.custom_number_records <= 0)
        ) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: ['custom_number_records'],
                message: 'You need to specify a valid number of records.',
            })
        }
    })

export function ExportForm<R>({
    table,
    recordName,
    all_records_operation_cost,
    onSubmit,
    onCancel,
    isRowBlurred,
}: {
    table: Table<R>
    recordName: string
    all_records_operation_cost: number
    onSubmit: (data: z.infer<typeof ExportFormSchema>) => void
    onCancel: () => void
    isRowBlurred: (row: R) => boolean
}) {
    const isThisPageVisible = table.getRowModel().rows.length !== (table.options?.meta?.getTotalResults() || 0)
    const isSelectionVisible =
        table.getSelectedRowModel().rows.length > 0 &&
        table.getSelectedRowModel().rows.length != table.getRowModel().rows.length &&
        table.getSelectedRowModel().rows.every((row) => isRowBlurred(row.original) == false)

    const form = useForm<z.infer<typeof ExportFormSchema>>({
        resolver: zodResolver(ExportFormSchema),
        defaultValues: {
            format: 'csv',
            numberOfRecords: isThisPageVisible ? 'this_page' : 'all',
            custom_number_records: table.options?.meta?.getTotalResults(),
        },
    })

    const thisPageCredits = useMemo(() => {
        const rows = table.getRowModel().rows
        const has_blurred_data_rows = rows.filter((row) => isRowBlurred(row.original))
        return has_blurred_data_rows.length
    }, [table])

    const selectedRowsCredits = useMemo(() => {
        const rows = table.getSelectedRowModel().rows
        const has_blurred_data_rows = rows.filter((row) => isRowBlurred(row.original))
        return has_blurred_data_rows.length
    }, [table])

    const { me } = useMe()
    const {
        isCalculatingCreditsCost: isCalculatingCustomCreditsCost,
        operationCreditsCost: customOperationCreditsCost,
    } = useCalculateOperationCreditsCost({
        table,
        limit: form.getValues('custom_number_records') || 0,
        enabled: form.watch('numberOfRecords') === 'custom',
    })

    const operationCreditsCost = useMemo(() => {
        const { numberOfRecords } = form.watch()
        if (numberOfRecords === 'this_page') {
            return thisPageCredits
        } else if (numberOfRecords === 'selection') {
            return selectedRowsCredits
        } else if (numberOfRecords === 'all') {
            return all_records_operation_cost
        } else {
            return customOperationCreditsCost || 0
        }
    }, [
        form.watch('numberOfRecords'),
        form.watch('custom_number_records'),
        customOperationCreditsCost,
        thisPageCredits,
        all_records_operation_cost,
    ])

    const hasEnoughCredits = useMemo(() => {
        if (!me) return true
        return me?.team?.credits_left_current_period >= operationCreditsCost
    }, [me?.team?.credits_left_current_period, operationCreditsCost])

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
                <FormField
                    control={form.control}
                    name="numberOfRecords"
                    render={({ field }) => (
                        <FormItem className="space-y-3">
                            <FormLabel>Number of {recordName}</FormLabel>
                            <FormControl>
                                <RadioGroup
                                    onValueChange={field.onChange}
                                    defaultValue={field.value}
                                    className="flex flex-col space-y-1"
                                >
                                    <FormItem
                                        className={`flex items-center space-x-2 space-y-0 ${isThisPageVisible ? '' : 'hidden'}`}
                                    >
                                        <FormControl>
                                            <RadioGroupItem value="this_page" />
                                        </FormControl>
                                        <FormLabel className="font-normal cursor-pointer">
                                            This page{' '}
                                            <span className="text-muted-foreground">
                                                ({table.getRowModel().rows.length} {recordName})
                                            </span>
                                        </FormLabel>
                                        {thisPageCredits > 0 && <CreditsBadge credits={thisPageCredits} />}
                                    </FormItem>
                                    <FormItem
                                        className={`flex items-center space-x-2 space-y-0 ${isSelectionVisible ? '' : 'hidden'}`}
                                    >
                                        <FormControl>
                                            <RadioGroupItem value="selection" />
                                        </FormControl>
                                        <FormLabel className="font-normal cursor-pointer">
                                            Selected rows{' '}
                                            <span className="text-muted-foreground">
                                                ({table.getSelectedRowModel().rows.length} {recordName})
                                            </span>
                                        </FormLabel>
                                        {selectedRowsCredits > 0 && <CreditsBadge credits={selectedRowsCredits} />}
                                    </FormItem>

                                    <FormItem className="flex items-center space-x-2 space-y-0">
                                        <FormControl>
                                            <RadioGroupItem value="all" />
                                        </FormControl>
                                        <FormLabel className="font-normal cursor-pointer">
                                            All{' '}
                                            <span className="text-muted-foreground">
                                                ({formatNumberWithComma(table.options?.meta?.getTotalResults() || 0)}{' '}
                                                {recordName})
                                            </span>
                                        </FormLabel>
                                        {all_records_operation_cost > 0 && (
                                            <CreditsBadge credits={all_records_operation_cost} />
                                        )}
                                    </FormItem>
                                    <FormItem className="flex items-center space-x-2 space-y-0">
                                        <FormControl>
                                            <RadioGroupItem value="custom" />
                                        </FormControl>
                                        <FormLabel className="font-normal cursor-pointer">Custom</FormLabel>
                                    </FormItem>
                                </RadioGroup>
                            </FormControl>
                            {form.watch('numberOfRecords') === 'custom' && (
                                <FormField
                                    control={form.control}
                                    name="custom_number_records"
                                    render={({ field }) => (
                                        <FormItem className="-mt-4 ml-4 ">
                                            <div className="flex items-center gap-2">
                                                <FormControl className="">
                                                    <Input
                                                        className="w-32 h-7 arrow-hide input"
                                                        type="number"
                                                        {...field}
                                                    />
                                                </FormControl>
                                                {isCalculatingCustomCreditsCost && <Spinner className="w-4 h-4" />}
                                                {customOperationCreditsCost != undefined &&
                                                    customOperationCreditsCost > 0 && (
                                                        <CreditsBadge credits={customOperationCreditsCost} />
                                                    )}
                                            </div>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            )}
                            <FormMessage />
                        </FormItem>
                    )}
                />
                {!hasEnoughCredits && (
                    <NotEnoughCredits
                        credits_left_current_period={me?.team?.credits_left_current_period || 0}
                        operation_credits_cost={operationCreditsCost}
                    />
                )}

                <FormField
                    control={form.control}
                    name="format"
                    render={({ field }) => (
                        <FormItem className="space-y-3">
                            <FormLabel>Format</FormLabel>
                            <FormControl>
                                <RadioGroup
                                    onValueChange={field.onChange}
                                    defaultValue={field.value}
                                    className="flex flex-col space-y-1"
                                >
                                    <FormItem className="flex items-center space-x-2 space-y-0">
                                        <FormControl>
                                            <RadioGroupItem value="csv" />
                                        </FormControl>
                                        <FormLabel className="font-normal cursor-pointer">
                                            .CSV (comma separated values)
                                        </FormLabel>
                                    </FormItem>
                                    <FormItem className="flex items-center space-x-2 space-y-0">
                                        <FormControl>
                                            <RadioGroupItem value="xlsx" />
                                        </FormControl>
                                        <FormLabel className="font-normal cursor-pointer">
                                            .XLSX (Microsoft Excel)
                                        </FormLabel>
                                    </FormItem>
                                </RadioGroup>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <div className="flex items-center justify-end gap-2">
                    <Button variant="outline" type="button" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button type="submit" disabled={!hasEnoughCredits || isCalculatingCustomCreditsCost}>
                        Export
                    </Button>
                </div>
            </form>
        </Form>
    )
}
