import { UserWithTeam } from '@/schemas/entities/user'
import { getMyProfile } from '@/services/user.service'
import { HOUR_IN_MS } from '@/shared/utils/date-utils'
import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import APIRequestTable from './api-request-table/api-request-table'

function APIPage() {
    useEffect(() => {
        document.title = 'API Usage and Requests · Settings · TheirStack'
    }, [])

    const { data: me = null } = useQuery<UserWithTeam>({
        queryKey: ['me'],
        queryFn: getMyProfile,
        staleTime: HOUR_IN_MS,
    })

    return (
        <>
            {me && (
                <APIRequestTable
                    currentPeriodStart={new Date(me.team.current_period_start)}
                    currentPeriodEnd={new Date(me.team.current_period_end)}
                />
            )}
        </>
    )
}

export default APIPage
