import { Sheet, SheetContent, SheetTitle } from '@/components/ui/base/sheet'
import CompanyCard from '@/components/ui/company/company-card'
import { countries } from '@/data/countries'
import { Job } from '@/schemas/entities/job.schema'
import { formatDateStringToReadableDate, formatDateTimeStringToReadableDate } from '@/shared/utils/date-utils'
import * as VisuallyHidden from '@radix-ui/react-visually-hidden'
import { CalendarIcon, DollarSign, GlobeIcon, Hash, Laptop, Link2 } from 'lucide-react'
import { forwardRef, useImperativeHandle, useState } from 'react'
import { Separator } from '../../../shared/ui/base/separator'
import StyledMarkdown from '../base/styled-markdown'
import { OpenDialogHandle } from '../dialog/dialog-handle-interface'

interface JobModalProps {
    job: Job
}

const JobModal = forwardRef<OpenDialogHandle, JobModalProps>(({ job }: JobModalProps, ref) => {
    const [isOpen, setIsOpen] = useState(false)

    useImperativeHandle(ref, () => ({
        open: () => setIsOpen(true),
        close: () => setIsOpen(false),
    }))

    const properties = [
        { name: 'Posted on', value: formatDateStringToReadableDate(job.date_posted), icon: <CalendarIcon size={15} /> },
        {
            name: 'Discovered on',
            value: formatDateTimeStringToReadableDate(job.discovered_at),
            icon: <CalendarIcon size={15} />,
        },
        { name: 'Remote', value: job.remote ? 'Yes' : 'False', icon: <Laptop size={15} /> },
        { name: 'Salary', value: job.salary_string, icon: <DollarSign size={15} /> },
        {
            name: 'Country',
            value: job.country_codes?.map((code) => countries.find((country) => country.id === code)?.label).join(', '),
            icon: <GlobeIcon size={15} />,
        },
        { name: 'Location', value: job.location, icon: <GlobeIcon size={15} /> },
        { name: 'TheirStack Job ID', value: job.id, icon: <Hash size={15} /> },
    ]
    return (
        <Sheet open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
            <SheetContent className="max-w-[800px] overflow-auto py-4" aria-describedby={undefined}>
                <VisuallyHidden.Root asChild>
                    <SheetTitle>Mobile Nav Bar</SheetTitle>
                </VisuallyHidden.Root>

                <div className="space-y-2">
                    <h3 className="text-xl font-medium flex"> {job.job_title}</h3>
                    <Separator />

                    <div className="py-4 flex space-y-3 flex-col">
                        {properties
                            .filter((property) => property.value)
                            .map((property) => (
                                <div key={property.name} className="flex items-center space-x-2 ">
                                    <div className=" flex-none">{property.icon}</div>

                                    <div className="w-32 flex-none">
                                        <p className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                            {property.name}
                                        </p>
                                    </div>
                                    <div className="">
                                        <p className="text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 max-w-full">
                                            {property.value}
                                        </p>
                                    </div>
                                </div>
                            ))}

                        <div className="flex items-center space-x-2">
                            <Link2 size={15} />
                            <p className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 w-32">
                                URL
                            </p>
                            {job.has_blurred_data == false && (
                                <a
                                    href={job.url}
                                    target="_blank"
                                    className="text-sm underline truncate"
                                    rel="noreferrer noopener"
                                    style={{ maxWidth: 400 }}
                                >
                                    {job.url}
                                </a>
                            )}
                            {job.has_blurred_data == true && (
                                <a className="text-sm underline truncate blur" style={{ maxWidth: 400 }}>
                                    {job.url}
                                </a>
                            )}
                        </div>

                        {job.source_url && job.source_url != job.url && (
                            <div className="flex items-center space-x-2">
                                <Link2 size={15} />
                                <p className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 w-32">
                                    Source URL
                                </p>
                                {job.has_blurred_data == false && (
                                    <a
                                        href={job.source_url}
                                        target="_blank"
                                        rel="noreferrer noopener"
                                        className="text-sm underline truncate"
                                        style={{ maxWidth: 400 }}
                                    >
                                        {job.source_url}
                                    </a>
                                )}
                                {job.has_blurred_data == true && (
                                    <a className="text-sm underline truncate blur" style={{ maxWidth: 400 }}>
                                        {job.source_url}
                                    </a>
                                )}
                            </div>
                        )}
                    </div>

                    {job.company_object && (
                        <>
                            <p className="text-lg font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 w-48 py-2">
                                Company
                            </p>
                            <Separator />
                            <CompanyCard company={job.company_object} />
                        </>
                    )}
                    {job.description && (
                        <>
                            <p className="text-lg font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 w-48 py-2 pt-6">
                                Description
                            </p>
                            <Separator />
                            <div className={job.has_blurred_data ? 'blur-sm' : ''}>
                                <StyledMarkdown markdown={job.description} />
                            </div>
                        </>
                    )}
                </div>
            </SheetContent>
        </Sheet>
    )
})

export default JobModal
