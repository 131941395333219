import { isArrayEmpty } from '@/lib/array-utils'
import { JobsSearchParams } from '@/schemas/request/jobs-search-params.schema'
import { VisibilityState } from '@tanstack/react-table'
import { useEffect } from 'react'

// Show matching phrases and words columns if they are not empty
export function useColumnsVisibilityBasedOnSearch(
    search: JobsSearchParams,
    columnVisibility: VisibilityState,
    setColumnVisibility: React.Dispatch<React.SetStateAction<VisibilityState>>
) {
    useEffect(() => {
        const show_mathching_phrases_column =
            !isArrayEmpty(search.job_description_pattern_or) ||
            !isArrayEmpty(search.job_description_pattern_not) ||
            !isArrayEmpty(search.job_technology_slug_or) ||
            !isArrayEmpty(search.job_technology_slug_not) ||
            !isArrayEmpty(search.job_technology_slug_and)

        const show_reports_to_column = search.reports_to_exists == true

        setColumnVisibility({
            ...columnVisibility,
            matching_phrases: show_mathching_phrases_column,
            manager_roles: show_reports_to_column,
        })
    }, [search])

    return {}
}
