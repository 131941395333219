import { PageLayout } from '@/components/ui/layout/page_layout'
import { HOME_PATH } from '@/paths'

function NotFoundPage() {
    return (
        <PageLayout>
            <main className="mx-auto flex w-full max-w-7xl flex-auto flex-col justify-center px-6 py-12 lg:px-8">
                <p className="text-base font-semibold leading-8 text-primary">404</p>
                <h1 className="mt-4 text-3xl font-bold tracking-tight sm:text-5xl">Page not found</h1>
                <p className="mt-6 text-base leading-7 text-gray-600">
                    You found a page that doesn't exist or that you don't have access to.
                </p>
                <div className="mt-10">
                    <a href={HOME_PATH} className="text-sm font-semibold leading-7 text-muted-foreground">
                        <span aria-hidden="true">&larr;</span> Back to home
                    </a>
                </div>
            </main>
        </PageLayout>
    )
}
export default NotFoundPage
