import { WithTooltip } from '@/shared/ui/base/tooltip'
import CreditsBadge from './credits-badge'

export default function CreditsActionTooltip({
    children,
    credits,
    object_name,
    credit_type = 'company',
}: {
    children: React.ReactNode
    credits: number
    object_name: 'company' | 'job'
    credit_type?: 'company' | 'API'
}) {
    return (
        <WithTooltip
            content={
                <div className="flex flex-row items-center gap-2">
                    <p>Use</p>
                    <CreditsBadge credits={credits} type={credit_type} size="sm" />
                    {object_name === 'company' && <p>to reveal {getPluralizedEntityName('company', credits)}</p>}
                    {object_name === 'job' && <p>to reveal jobs from {getPluralizedEntityName('company', credits)}</p>}
                </div>
            }
        >
            {children}
        </WithTooltip>
    )
}

const getPluralizedEntityName = (entityType: 'company' | 'job', count: number) => {
    return entityType === 'company'
        ? count > 1
            ? `${count} companies`
            : 'this company'
        : count > 1
          ? `${count} jobs`
          : 'this job'
}
