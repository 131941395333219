import { Button } from '@/shared/ui/base/button'
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@/shared/ui/base/dialog'
import { Trash } from 'lucide-react'
import { useState } from 'react'

function RemoveMembersButton() {
    const [open, setOpen] = useState(false)

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <Button variant="outline" size="icon">
                    <Trash className="w-4 h-4" />
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>Remove members</DialogTitle>
                </DialogHeader>
                <p className="text-sm text-muted-foreground">
                    Please send us an email to founders@theirstack.com to remove members from your team.
                </p>
            </DialogContent>
        </Dialog>
    )
}

export default RemoveMembersButton
