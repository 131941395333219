'use client'

import { BillingPrice } from '@/shared/schemas/entity/billing-price.schema'
import { Currency } from '@/shared/schemas/response/geolocation.schema'
import { Button } from '@/shared/ui/base/button'
import HelpCreditTooltip from '@/shared/ui/pricing/help_credit_tooltip'
import { formatMoney, formatNumberWithComma, formatStripeNumber } from '@/shared/utils/number-utils'
import { useEffect, useMemo, useState } from 'react'

import { BillingProduct } from '@/shared/schemas/entity/billing-product.schema'
import { Tabs, TabsList, TabsTrigger } from '../../base/tabs'
import PricingCard from '../pricing-card'

export const API_CREDIT_CONTENT = (
    <p className="text-xs">
        A company credit allows you to track one company for a month, giving you access to all job postings,
        technologies used and firmographics.{' '}
        <a href="https://theirstack.com/en/pricing#faqs" target="_blank" className="text-blue-500 underline">
            Learn more
        </a>
    </p>
)

export const getCreditPrice = (unit_amount: number, credits: string | undefined, currency: Currency) => {
    return formatMoney(formatStripeNumber(unit_amount) / +(credits ?? 1), currency, 3)
}

export const FREE_APP_CREDITS = 50

export default function AppPricing({
    products,
    currency,
    onBillingPriceSelected,
    mode,
    showFreePlan = false,
}: {
    products: BillingProduct[]
    mode: 'both' | 'one_time' | 'recurring'
    currency: Currency
    onBillingPriceSelected: (price_id: string | undefined) => void
    showFreePlan?: boolean
}) {
    const recurrentPrices = products
        .filter((product) => product.prices.some((price) => price.type === 'recurring'))
        .map((product) => product.prices.map((price) => ({ ...price, metadata: product.metadata })))
        .flat()
        .filter((price) => price.recurring_interval == 'month') //exclude yearly plans
        .sort((a, b) => a.unit_amount - b.unit_amount)

    const oneTimePrices = products
        .filter((product) => product.prices.some((price) => price.type === 'one_time'))
        .map((product) => product.prices)
        .flat()
        .sort((a, b) => a.unit_amount - b.unit_amount)

    const [selectedMode, setSelectedMode] = useState<'one_time' | 'recurring'>(mode == 'both' ? 'one_time' : mode)
    const allPrices = useMemo(
        () =>
            [...oneTimePrices, ...recurrentPrices].filter(
                (price) => price.currency === currency && price.type === selectedMode
            ),
        [oneTimePrices, recurrentPrices, currency, selectedMode]
    )

    const [selectedPrice, setSelectedPrice] = useState<BillingPrice | undefined>(allPrices[0])

    useEffect(() => {
        if (selectedPrice && selectedPrice.type !== selectedMode) {
            const price_same_credits = allPrices.find(
                (price) => price.metadata.credits === selectedPrice.metadata.credits && price.type === selectedMode
            )
            setSelectedPrice(price_same_credits ?? (selectedMode == 'one_time' ? oneTimePrices[0] : recurrentPrices[0]))
        }
    }, [selectedMode, allPrices, currency, mode, selectedPrice, oneTimePrices, recurrentPrices])

    return (
        <div className="flex flex-col gap-4">
            <p className="text-xs md:text-sm text-gray-500">
                Our{' '}
                <a href="https://app.theirstack.com" target="_blank" className="text-primary underline">
                    TheirStack UI
                </a>{' '}
                plans offer easy access to all features, including exporting company lists based on technology usage,
                searching for jobs across multiple job boards simultaneously, performing detailed company lookups, and
                more.
            </p>

            <div className="flex flex-col gap-4">
                <div className="w-full grid grid-cols-1 md:grid-cols-5 gap-2">
                    <div className="md:col-span-3 flex flex-col gap-2">
                        {showFreePlan && (
                            <PricingCard
                                active={selectedPrice === undefined}
                                onClick={() => setSelectedPrice(undefined)}
                            >
                                <p className="p-2 rounded-l-md text-sm md:text-base">
                                    {FREE_APP_CREDITS} company credits
                                </p>
                                <p className="p-2 rounded-r-md">
                                    Free
                                    {selectedMode === 'recurring' && (
                                        <span className="text-xs font-medium text-gray-600 ml-1">{`/month`}</span>
                                    )}
                                </p>
                            </PricingCard>
                        )}
                        {allPrices
                            .filter((price) => price.currency === currency && price.type === selectedMode)
                            .map((item: BillingPrice) => (
                                <PricingCard
                                    key={item.id}
                                    active={selectedPrice?.id === item.id}
                                    onClick={() => setSelectedPrice(item)}
                                >
                                    <p className="text-sm md:text-base p-2 rounded-l-md">
                                        {formatNumberWithComma(+(item.metadata.credits ?? 1))} company credits
                                    </p>
                                    <span className="hidden md:inline-flex p-2 text-gray-500 text-xs  items-center gap-1">
                                        <p>
                                            {getCreditPrice(item.unit_amount, item.metadata.credits, item.currency)} per
                                            company credit
                                        </p>
                                        <HelpCreditTooltip>{API_CREDIT_CONTENT}</HelpCreditTooltip>
                                    </span>
                                    <p className="p-2 rounded-r-md">
                                        {formatMoney(formatStripeNumber(item.unit_amount), item.currency)}
                                        {item.recurring_interval == 'month' && (
                                            <span className="ml-1 text-xs font-medium text-gray-600">{`/month`}</span>
                                        )}
                                    </p>
                                </PricingCard>
                            ))}
                    </div>

                    <div className="col-span-2 flex flex-col gap-2">
                        <Tabs
                            defaultValue={selectedMode}
                            onValueChange={(value) => setSelectedMode(value as 'one_time' | 'recurring')}
                        >
                            <div className="flex justify-center">
                                <TabsList
                                    className={`grid h-auto p-0 border ${mode == 'both' ? 'grid-cols-2' : 'grid-cols-1'}`}
                                >
                                    {mode != 'recurring' && (
                                        <TabsTrigger
                                            value="one_time"
                                            className="grid grid-flow-row auto-rows-max h-auto data-[state=active]:shadow-none data-[state=active]:border-b-4 data-[state=active]:border-b-primary"
                                        >
                                            <p className="text-md font-semibold">Pay as you go</p>
                                            <p className="text-xs md:text-xs text-gray-500 text-wrap">
                                                One-time purchase
                                            </p>
                                        </TabsTrigger>
                                    )}
                                    {mode != 'one_time' && (
                                        <TabsTrigger
                                            value="recurring"
                                            className="grid grid-flow-row auto-rows-max h-auto data-[state=active]:shadow-none data-[state=active]:border-b-4 data-[state=active]:border-b-primary"
                                        >
                                            <span className="flex flex-row items-center justify-center">
                                                <p className="text-md font-semibold">Subscription</p>
                                                <div className="ml-1 bg-yellow-600 rounded-md p-0.5">
                                                    <p className="text-xs text-white font-medium">-10%</p>
                                                </div>
                                            </span>
                                            <p className="text-xs md:text-xs text-gray-500 text-wrap">
                                                New credits every month
                                            </p>
                                        </TabsTrigger>
                                    )}
                                </TabsList>
                            </div>
                        </Tabs>
                        {selectedPrice && (
                            <div className="flex justify-center items-center bg-gray-100 rounded-md p-4 mt-2">
                                <div className="flex flex-col items-center gap-4">
                                    <p className="text-4xl">
                                        {formatMoney(
                                            formatStripeNumber(selectedPrice.unit_amount),
                                            selectedPrice.currency
                                        )}
                                        {selectedMode === 'recurring' && (
                                            <span className="ml-1 text-xs font-medium text-gray-600">{`/month`}</span>
                                        )}
                                    </p>
                                    <p className="text-lg">
                                        {formatNumberWithComma(+(selectedPrice.metadata.credits ?? 1))} companies
                                    </p>
                                    <div className="flex items-center gap-1 text-gray-500">
                                        <p className="text-sm">
                                            {getCreditPrice(
                                                selectedPrice.unit_amount,
                                                selectedPrice.metadata.credits,
                                                selectedPrice.currency
                                            )}{' '}
                                            per company credit
                                        </p>
                                        <HelpCreditTooltip>{API_CREDIT_CONTENT}</HelpCreditTooltip>
                                    </div>
                                    <Button onClick={() => onBillingPriceSelected(selectedPrice.id)}>
                                        {selectedMode == 'one_time' ? 'Purchase' : 'Subscribe'}
                                    </Button>
                                </div>
                            </div>
                        )}
                        {!selectedPrice && (
                            <div className="flex justify-center items-center bg-gray-100 rounded-md p-4 mt-2">
                                <div className="flex flex-col items-center gap-4">
                                    <p className="text-4xl">
                                        Free
                                        {selectedMode === 'recurring' && (
                                            <span className="ml-1 text-xs font-medium text-gray-600">{`/month`}</span>
                                        )}
                                    </p>
                                    <p className="text-lg">{FREE_APP_CREDITS} companies</p>

                                    <Button onClick={() => onBillingPriceSelected(undefined)}>Start now</Button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {selectedMode == 'one_time' && (
                    <p className="text-xs text-gray-500">Company credits last for 12 months after purchase date.</p>
                )}
            </div>
        </div>
    )
}
