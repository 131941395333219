export const funding_stages = [
    { id: 'Angel', label: 'Angel' },
    { id: 'Convertible Note', label: 'Convertible Note' },
    { id: 'Debt Financing', label: 'Debt Financing' },
    { id: 'Equity Crowdfunding', label: 'Equity Crowdfunding' },
    { id: 'Other', label: 'Other' },
    { id: 'Private Equity', label: 'Private Equity' },
    { id: 'Seed', label: 'Seed' },
    { id: 'Series A', label: 'Series A' },
    { id: 'Series B', label: 'Series B' },
    { id: 'Series C', label: 'Series C' },
    { id: 'Series D', label: 'Series D' },
    { id: 'Series E', label: 'Series E' },
    { id: 'Series F', label: 'Series F' },
    { id: 'Series G', label: 'Series G' },
    { id: 'Series H', label: 'Series H' },
    { id: 'Venture (Round not Specified)', label: 'Venture (Round not Specified)' },
]
