import { Checkbox } from '../base/checkbox'

export default function PricingCard({
    children,
    active,
    className,
    onClick,
}: {
    children: React.ReactNode
    active: boolean
    className?: string
    onClick?: () => void
}) {
    return (
        <div
            className={`flex flex-row items-center gap-2 px-2 rounded-md border cursor-pointer ${active ? 'border-primary' : ''} ${className}`}
            onClick={onClick}
        >
            <Checkbox checked={active} onCheckedChange={onClick} />
            <div className="flex flex-row justify-between items-center w-full">{children}</div>
        </div>
    )
}
