import { countries } from '@/data/countries'

export default function CountryCard({ countryIso }: { countryIso: string }) {
    const country = countries.find((country) => country.id === countryIso)
    return (
        <>
            {country != undefined && country != null && (
                <div className="grid grid-flow-col gap-2 auto-cols-max items-center">
                    <img
                        className="w-5 h-5 rounded-sm object-scale-down"
                        src={country.flag}
                        width="10"
                        height="10"
                        alt="Country flag"
                    />
                    <p>{country.label}</p>
                </div>
            )}
        </>
    )
}
