'use client'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import * as React from 'react'

import { cn } from '@/lib/utils'

const TooltipProvider = TooltipPrimitive.Provider

const Tooltip = TooltipPrimitive.Root

const TooltipTrigger = TooltipPrimitive.Trigger

const TooltipContent = React.forwardRef<
    React.ElementRef<typeof TooltipPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
    <TooltipPrimitive.Content
        ref={ref}
        sideOffset={sideOffset}
        className={cn(
            'z-50 overflow-hidden rounded-md bg-gray-800 px-3 py-1.5 text-sm text-white shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
            className
        )}
        {...props}
    />
))
TooltipContent.displayName = TooltipPrimitive.Content.displayName

interface WithTooltipProps {
    children: React.ReactNode
    title?: string
    message?: string
    content?: React.ReactNode
    side?: 'top' | 'right' | 'bottom' | 'left'
}

const WithTooltip: React.FC<WithTooltipProps> = ({ children, title, message, content, side = 'top' }) => {
    return (
        <TooltipProvider delayDuration={100}>
            <Tooltip>
                <TooltipTrigger
                    asChild
                    onClick={(event) =>
                        event.preventDefault()
                    } /* Prevent the tooltip from being closed when clicking the trigger. Important for mobile where there is no hover */
                >
                    <span tabIndex={0}>{children}</span>
                </TooltipTrigger>
                <TooltipContent
                    className="flex flex-col items-center space-y-1"
                    side={side}
                    onPointerDownOutside={(event) =>
                        event.preventDefault()
                    } /* Prevent the tooltip from being closed when clicking the trigger. Important for mobile where there is no hover  */
                >
                    {content && content}
                    {!content && (
                        <>
                            <p>{title}</p>
                            {message && <p className="text-xs text-gray-300">{message}</p>}
                        </>
                    )}
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    )
}

export { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, WithTooltip }
