import {
    ColumnDef,
    ColumnFiltersState,
    SortingState,
    VisibilityState,
    getCoreRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table'
import * as React from 'react'

import { DataTable } from '@/components/ui/table/data-table'
import { DataTablePagination } from '@/components/ui/table/data-table-pagination'
import { TechDetails } from '@/schemas/entities/tech-details.schema'
import { TechSearchParams } from '@/schemas/request/tech-search-params.schema'
import { hiddenTechnologyColumns } from './colums'
import { DataTableToolbarTechnologies } from './data-table-toolbar'

interface DataTableProps<TValue> {
    columns: ColumnDef<TechDetails, TValue>[]
    data: TechDetails[]
    isDataFetching: boolean
    sorting: SortingState
    search_params: TechSearchParams
    filter_by?: string
}

export function DataTableTechnologies<TValue>({
    columns,
    data,
    isDataFetching,
    sorting: initialSorting,
    search_params,
    filter_by,
}: DataTableProps<TValue>) {
    const [rowSelection, setRowSelection] = React.useState({})
    const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>(hiddenTechnologyColumns)
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([])
    const [sorting, setSorting] = React.useState<SortingState>(initialSorting)

    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
            columnVisibility,
            rowSelection,
            columnFilters,
            pagination: {
                pageIndex: 0,
                pageSize: 9999,
            },
        },
        enableRowSelection: true,
        onRowSelectionChange: setRowSelection,
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        onColumnVisibilityChange: setColumnVisibility,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        meta: {
            getTotalResults: () => data.length,
            getBulkSelectionData: () => [],
            calculateCredits: () => Promise.resolve(0),
            fetchAndAddRowsIncrementally: () => Promise.resolve({ is_out_of_credits: false }),
        },
    })

    return (
        <>
            <DataTableToolbarTechnologies table={table} filter_by={filter_by} search_params={search_params} />
            <DataTable table={table} columns={columns} isDataFetching={isDataFetching} />
            <DataTablePagination table={table} totalResults={data?.length || 0} />
        </>
    )
}
