import { filtersToParams, paramsToFilters } from '@/components/ui/search-filter/filter-mapper'
import { areObjectsEqual } from '@/lib/search-params-utils'
import { filters } from '@/pages/search/jobs/components/filters'
import { Pagination } from '@/schemas/entities/pagination.schema'
import { BaseSearchParams } from '@/schemas/request/base-search-params.schema'
import { APIResponse, Metadata } from '@/schemas/responses/response-base.schema'
import { getJobCountFromSearchParams } from '@/services/job.service'
import { IFiltersProps } from '@/shared/schemas/entity/filter.schema'
import { useQueryClient } from '@tanstack/react-query'
import {
    ColumnDef,
    ColumnFiltersState,
    OnChangeFn,
    PaginationState,
    RowSelectionState,
    SortingState,
    VisibilityState,
    getCoreRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table'
import { useEffect, useMemo, useState } from 'react'
import { useErrorNotification } from '../toast/use-error-notification'

export const FIELDS_TO_OMIT_FOR_COMPARISON = ['page', 'limit', 'include_total_results', 'blur_company_data']
export const fromSearchParamsToSortingState = (searchParams: BaseSearchParams): SortingState => {
    const sortingState: SortingState = []
    if (Array.isArray(searchParams?.order_by) && searchParams.order_by.length > 0) {
        const order_by = searchParams.order_by[0]
        sortingState.push({ id: order_by.field, desc: order_by.desc })
    }
    return sortingState
}
export const getDefaultMetadata = (): Metadata => {
    return {
        total_results: -1,
        total_companies: -1,
        truncated_results: 0,
        truncated_companies: 0,
    }
}
export function useFetchDataAndTotalResultsSeparatedForTable<TSearchParams extends BaseSearchParams, TData>(
    savedSearch: TSearchParams,
    fetchDataFromAPI: (
        searchParams: TSearchParams,
        pagination: Pagination,
        include_total_results: boolean
    ) => Promise<APIResponse<TData>>,
    columns: ColumnDef<TData>[],
    hiddenColumns: VisibilityState,
    fetchOnMount: boolean = true
) {
    const [searchedSP, setSearchedSP] = useState<TSearchParams>(savedSearch)
    const [dirtySP, setDirtySP] = useState<TSearchParams>(savedSearch)
    const [data, setData] = useState<TData[]>([])
    const [bulkSelectionData, setBulkSelectionData] = useState<TData[]>([])
    const [metadata, setMetadata] = useState<Metadata>(getDefaultMetadata())
    const [isDataFetching, setIsDataFetching] = useState<boolean>(false)
    const [wasFirstSearchTriggered, setWasFirstSearchTriggered] = useState<boolean>(false)
    const [totalResults, setTotalResults] = useState<number>(0)
    const [pagination, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: 25 })
    const [sorting, setSorting] = useState<SortingState>([])
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(hiddenColumns)
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({})
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
    const { showErrorNotification } = useErrorNotification({ isError: false })
    const queryClient = useQueryClient()
    const [appliedFilters, setAppliedFilters] = useState<IFiltersProps[]>([])

    const isCurrentSearchSaved = useMemo(() => {
        return areObjectsEqual(dirtySP, savedSearch, FIELDS_TO_OMIT_FOR_COMPARISON)
    }, [dirtySP, savedSearch])

    const isCurrentSearchSearched = useMemo(() => {
        if (!wasFirstSearchTriggered) return false
        return areObjectsEqual(dirtySP, searchedSP, FIELDS_TO_OMIT_FOR_COMPARISON)
    }, [wasFirstSearchTriggered, dirtySP, searchedSP])

    useEffect(() => {
        if (!areObjectsEqual(searchedSP, savedSearch, FIELDS_TO_OMIT_FOR_COMPARISON) || !wasFirstSearchTriggered) {
            const savedFilters = paramsToFilters(savedSearch, filters)
            const missingPermanentFilters = filters
                .filter((filter) => filter.is_permanent)
                .filter((filter) => !savedFilters.some((newFilter) => newFilter.id === filter.id))
                .map((filter) => ({ ...filter, operator: filter.availableOperators[0] }))
            const newFilters = [...savedFilters, ...missingPermanentFilters]
            setAppliedFilters(newFilters)

            setSearchedSP(savedSearch)
            setDirtySP(savedSearch)
            if (!fetchOnMount) return
            setSorting(fromSearchParamsToSortingState(savedSearch))
            fetchData(savedSearch, pagination)
            fetchTotalResults(savedSearch)
        }
    }, [savedSearch])

    const onChangeFilters = (newSearchParams: TSearchParams) => {
        setSearchedSP(newSearchParams)
        const newPagination = resetPagination()
        fetchData(newSearchParams, newPagination)
        fetchTotalResults(newSearchParams)
        resetSelection()
    }

    const onChangePagination: OnChangeFn<PaginationState> = (
        updaterOrValue: PaginationState | ((old: PaginationState) => PaginationState)
    ) => {
        if (!searchedSP) return
        const updater = typeof updaterOrValue === 'function' ? updaterOrValue : () => updaterOrValue
        const newPagination = updater(pagination)
        setPagination(newPagination)
        resetSelection()
        fetchData(searchedSP, newPagination)
    }

    const onChangeSorting: OnChangeFn<SortingState> = (
        updaterOrValue: SortingState | ((old: SortingState) => SortingState)
    ) => {
        if (!searchedSP) return
        const updater = typeof updaterOrValue === 'function' ? updaterOrValue : () => updaterOrValue
        const newSorting = updater(sorting)
        setSorting(newSorting)
        const newSearch = { ...searchedSP, order_by: [{ field: newSorting[0]?.id, desc: newSorting[0]?.desc }] }
        setSearchedSP(newSearch)
        setDirtySP(newSearch)
        const newPagination = resetPagination()
        resetSelection()
        fetchData(newSearch, newPagination)
    }

    const fetchData = async (search: TSearchParams, ps: PaginationState) => {
        setWasFirstSearchTriggered(true)
        setIsDataFetching(true)
        fetchDataFromAPI(search, ps, false).then(
            (res) => {
                setData(res.data)
                setIsDataFetching(false)
                queryClient.invalidateQueries({ queryKey: ['me'] })
            },
            (error) => {
                console.error(error)
                showErrorNotification()
                setIsDataFetching(false)
            }
        )
    }

    const fetchTotalResults = (search: TSearchParams) => {
        setTotalResults(-1)
        setMetadata(getDefaultMetadata())
        fetchDataFromAPI(search, { pageIndex: 0, pageSize: 1 }, true).then(
            (data) => {
                setTotalResults(data.metadata.total_results || 0)
                setMetadata(data.metadata)
            },
            (error) => {
                setTotalResults(0)
                console.error(error)
                showErrorNotification()
            }
        )
    }

    const fetchAndAddRowsIncrementally = async (offset: number, limit: number = 100) => {
        const newPagination = { offset: offset, pageSize: limit }
        const unblurred_params = { ...searchedSP, blur_company_data: false }
        return fetchDataFromAPI(unblurred_params, newPagination, false).then(
            (response) => {
                const mergedData = offset != 0 ? [...bulkSelectionData, ...response.data] : response.data
                if (offset == 0 && pagination.pageSize <= limit) setData(mergedData.slice(0, pagination.pageSize)) // Update table revealing hidden data if is needed
                setBulkSelectionData(mergedData)
                return { is_out_of_credits: response.metadata.truncated_companies > 0 }
            },
            (e) => {
                if (e instanceof DOMException && e.name === 'AbortError') return { is_out_of_credits: false }
                console.error(e)
                showErrorNotification()
                return { is_out_of_credits: false }
            }
        )
    }

    const calculateCredits = async (n_companies: number) => {
        const newPagination = { pageIndex: 0, pageSize: n_companies }
        return getJobCountFromSearchParams(searchedSP, newPagination).then(
            (response) => {
                return response.companies.unrevealed
            },
            (e) => {
                if (e instanceof DOMException && e.name === 'AbortError') return 0
                console.error(e)
                showErrorNotification()
                return 0
            }
        )
    }

    const resetPagination = () => {
        const newPagination = { ...pagination, pageIndex: 0 }
        if (pagination.pageIndex != 0) setPagination(newPagination)
        return newPagination
    }

    const resetSelection = () => {
        setRowSelection({})
        setBulkSelectionData([])
    }

    const table = useReactTable({
        data: data,
        columns: columns,
        pageCount: Math.ceil(totalResults / pagination.pageSize),
        state: {
            sorting,
            pagination,
            columnVisibility,
            rowSelection,
            columnFilters,
        },
        enableRowSelection: true,
        manualPagination: true,
        manualSorting: true,
        onPaginationChange: onChangePagination,
        onRowSelectionChange: setRowSelection,
        onSortingChange: onChangeSorting,
        onColumnFiltersChange: setColumnFilters,
        onColumnVisibilityChange: setColumnVisibility,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        meta: {
            updateRow: (rowIndex, job: TData) => {
                setData((old) =>
                    old.map((row, index) => {
                        if (index === rowIndex) return job
                        return row
                    })
                )
            },
            getTotalResults: () => totalResults,
            fetchAndAddRowsIncrementally: fetchAndAddRowsIncrementally,
            getBulkSelectionData: () => bulkSelectionData,
            calculateCredits: calculateCredits,
        },
    })

    const onChangeFilter = (index: number, filter: IFiltersProps) => {
        const newFilters = [...appliedFilters]
        newFilters[index] = filter
        newFilters[index].hasChanged = true
        setAppliedFilters(newFilters)
        const newDirtySP = filtersToParams(newFilters, searchedSP.order_by) as TSearchParams
        setDirtySP(newDirtySP)
    }

    const onRemoveFilter = (index: number) => {
        const newFilters = [...appliedFilters]
        newFilters.splice(index, 1)
        setAppliedFilters(newFilters)
        if (newFilters.length === 0) {
            setWasFirstSearchTriggered(false)
        } else {
            const newDirtySP = filtersToParams(newFilters, searchedSP.order_by) as TSearchParams
            setDirtySP(newDirtySP)
        }
    }

    const addFilters = (filters: IFiltersProps[]) => {
        const newFilters = [...appliedFilters, ...filters]
        setAppliedFilters(newFilters)
    }

    const setFiltersAsNotChanged = () => {
        setAppliedFilters(appliedFilters.map((filter) => ({ ...filter, hasChanged: false })))
    }

    return {
        table,
        metadata,
        searchedSP,
        dirtySP,
        data,
        isDataFetching,
        totalResults,
        pagination,
        sorting,
        onChangeFilters,
        onChangePagination,
        onChangeSorting,
        isCurrentSearchSaved,
        isCurrentSearchSearched,
        setDirtySP,
        columnVisibility,
        wasFirstSearchTriggered,
        setColumnVisibility,
        appliedFilters,
        addFilters,
        onChangeFilter,
        onRemoveFilter,
        setFiltersAsNotChanged,
    }
}
